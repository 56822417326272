/**
 * 模块名称: 行业消耗汇总
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
    Table,
    Form,
    Icon,
    Input,
    Button,
    Select,
    Modal,
    message,
    Alert,
    DatePicker,
    Spin
} from 'antd'
import CorpMore from 'components/CorpMore'
import { parseSearch } from 'utils'
import Export from 'components/Export'
import Auth from '@/components/AuthMiddleware'
import Import from 'components/Import'

let selectedIds = ''
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker, MonthPicker } = DatePicker

let pageSize = 10
let currentPage = 1
let corpId = '0'
let searchParamsTmp = {
    limit: pageSize,
    page: currentPage,
    industry1: '0',             
    industry2: '0',
    orderStartDate: '0',
    orderEndDate: '0',
    projectId: '0',
    dataDimension: '0',
    corpId: corpId
}
let storeFilterContent = {}
let allFilter = {}
let allData = [{
    id: '0',
    name: '全部'
}]
const dimensionArr = [
    ...allData,
    {
        id: 1,
        name: '直销消耗总和'
    }, {
        id: 2,
        name: '渠道消耗总和'
    }, {
        id: 3,
        name: '客户数量统计'
    }]

const IndustrySum = (props) => {
    const { history, match, location } = props
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = props.form
    const productsRef = useRef()
    const ImportRefVendor = useRef()
    const [filterName, setFilterName] = useState(undefined)
    const [count, setCount] = useState(0)
    const [totalNum, setTotalNum] = useState('')
    const [list, setList] = useState([])
    const [columnVal, setColumn] = useState([])
    const [loading, setLoading] = useState(false)
    const [filterModelvisible, setFilterModelvisible] = useState(false)
    const [filterContent, setFilterContent] = useState([])
    const [showAll, setShowAll] = useState(false)
    const [showExport, setShowExport] = useState(false)
    const [statusValName, setStatusValName] = useState(undefined)
    const [flag, setFlag] = useState(false)
    const [authList, setAuthList] = useState([])
    const [filterInfoValue, setFilterInfoValue] = useState('')
    const search = parseSearch(location.search)
    const [checkedIdArr, setCheckedIdArr] = useState([])
    const [checked, setChecked] = useState(0)
    const [showLoading, setShowLoading] = useState(false)
    const [selectData, setSelectData] = useState({
        industry1Arr: [], // 一级
        industry2Arr: [], // 二级
        projectArr: [], // 项目
        isRequest: false
    })
    const [showVendorImport, setShowVendorImport] = useState(false) // 厂商导入
    const [installData, setinstallData] = useState(undefined)
    const [numFlag, setNumFlag] = useState(false)
    const [listFlag, setListFlag] = useState(false)

    useEffect(() => {
        currentPage = search.page ? +search.page : 1
        pageSize = search.limit ? +search.limit : 10
        searchParamsTmp = {
            limit: pageSize,
            page: currentPage,
            industry1: '0',             
            industry2: '0',
            orderStartDate: '0',
            orderEndDate: '0',
            projectId: '0',
            dataDimension: '0',
        }
        corpId = '0'
        storeFilterContent = {}
        getSelectData()
        getindustryConsumList()
        getindustryCountTotal()
        api.getPageAuth().then(list => setAuthList(list))
    }, [])

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        }
    }

    //点击选择所属公司
    const onChangeCorp = (data) => {
        currentPage = 1
        history.replace(match.path)
       if (data.length) {
           corpId = data && data.map(item => item.id).join(',')
           searchParamsTmp.corpId =  corpId == ''  ? '0' : corpId
        } else {
            corpId = '0'
            searchParamsTmp.corpId = '0'
        }
        // getindustryConsumList()
    }

    //点击重置
    const onReset = () => {
        currentPage = 1
        history.replace(match.path)
        searchParamsTmp = {
            limit: pageSize,
            page: currentPage,
            industry1: '0',             
            industry2: '0',
            orderStartDate: '0',
            orderEndDate: '0',
            projectId: '0',
            dataDimension: '0',
            corpId: corpId
        }
        resetFields()
        setFilterName(undefined)
        setStatusValName(undefined)
        getindustryConsumList()
        getindustryCountTotal()
        setCheckedIdArr([])
        setChecked(0)
        onIndustryTwo()
    }

    //分页点击
    const onChangeTable = (pagination, filters, sorter) => {
        searchParamsTmp.page = currentPage = pagination.current
        searchParamsTmp.limit = pageSize = pagination.pageSize
        history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
        getindustryConsumList()
    }

    //获取列表
    const getindustryConsumList = () => {
        setList([])
        setColumn([])
        setLoading(true)
        searchParamsTmp.corpId = corpId
        setListFlag(true)
        api.getindustryConsumList(searchParamsTmp).then(res => {
            // setCount(res.count)
            // setTotalNum(res)
            setList(res.list)
            setListFlag(false)
            setColumn(res.columns)
            if (currentPage === 1) {
                if (res.ids) {
                    localStorage.setItem('ids', JSON.stringify(res.ids))
                } else {
                    localStorage.setItem('ids', JSON.stringify([]))
                }
            }
            setLoading(false)
        }).catch(() => {
            setListFlag(false)
            setLoading(false)
        })
    }

    //获取统计
    const getindustryCountTotal = () => {
        setCount(0)
        setTotalNum('')
        setNumFlag(true)    
        searchParamsTmp.corpId = corpId
        api.getindustryCountTotal(searchParamsTmp).then(res => {
            setCount(res.count)
            setTotalNum(res)
            setNumFlag(false)      
        }).catch(() => {
            setNumFlag(false)
        })
    }

    //获取筛选项
    const getSelectData = () => {
        return Promise.all([
            api.getSysIndustryList({ pid: 0 }),
            api.getSysIndustryList({ pid: -1 }),
            api.getsysProjectChilds({ pid: 0 }),
        ]).then(list => {
            setSelectData({
                industry1Arr: [...allData, ...list[0]],
                industry2Arr: [...allData, ...list[1]],
                projectArr: [...allData, ...list[2]],
                isRequest: true
            })
            return list
        }).catch()
    }


    // 选择一级行业
    const onSelectIndustry = (val) => {
        setFieldsValue({
            industry2: '0'
        })
        api.getSysIndustryList({
            pid: val !== '0' ? val : -1,
        }).then(data => {
            setSelectData({
                ...selectData,
                industry2Arr: [...allData, ...data],
            })
        })
    }

    // 获取二级行业
    const onIndustryTwo = () => {
        api.getSysIndustryList({
            pid: getFieldValue('industry1') !== '0' ? getFieldValue('industry1') : -1,
        }).then(data => {
            setSelectData({
                ...selectData,
                industry2Arr: [...allData, ...data],
            })
        })
    }

    //数据转换
    const transData = (vals) => {
        if (vals !== undefined) {
            for (let i in vals) {
                if (vals[i] !== undefined) {
                    if (i == 'addTime') {
                        if(vals[i] == '') {
                            searchParamsTmp.orderStartDate = '0'
                            searchParamsTmp.orderEndDate = '0'
                        } else {
                            searchParamsTmp.orderStartDate = moment(vals[i][0]).format('YYYY-MM-DD')
                            searchParamsTmp.orderEndDate = moment(vals[i][1]).format('YYYY-MM-DD')
                        }
                    } else if (i == 'month') {
                        searchParamsTmp.month = moment(vals[i]).format('YYYY年MM月')
                    } else {
                        searchParamsTmp[i] = vals[i]
                    }
                }
            }
            // console.log('searchParamsTmp=>', searchParamsTmp)
        }
    }

    //点击筛选
    const searchSubmit = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            console.log(vals)
            transData(vals)
            if (!err) {
                searchParamsTmp.page = currentPage = 1
                history.replace(match.path)
                getindustryConsumList()
                getindustryCountTotal()
                setCheckedIdArr([])
                setChecked(0)
            }
        })
    }

    //选择保存方案
    const onChangeGetFilter = (val) => {
        val ? setFlag(true) : setFlag(false)
        // 下拉框搜索内容
        val && api.getSearchStorage({ key: location.pathname }).then(res => {
            setFilterContent(res.list)
        })
    }

    //选择方案
    const onFilterChecked = (item, val2) => {
        // console.log('item=>', item)
        setFilterName(item)
        setStatusValName(val2.props.children.props.children[0])
        let data = filterContent[item].content
        searchParamsTmp = { ...searchParamsTmp, ...data }
        setFieldsValue({
            industry1: data.industry1 ? data.industry1 : undefined,
            industry2: data.industry2 ? data.industry2 : undefined,
            projectId: data.projectId ? data.projectId : undefined,
            dataDimension: data.dataDimension ? data.dataDimension : undefined,
            addTime: data.addTime ? [moment(data.addTime[0]), moment(data.addTime[1])] : undefined
        })
    }

    // 筛选删除
    const onSearchDelete = (id) => {
        api.searchStorageDelete({
            id: id
        }).then(() => {
            api.getSearchStorage({ key: location.pathname }).then(data => {
                setFilterContent(data.list)
            })
        })
    }

    //保存筛选条件
    const onSaveFilterTerm = () => {
        validateFields((err, vals) => {
            //console.log(vals)
            if (!err) {
                const values = Object.values(vals)
                if (values.some(item => item === '' || item !== undefined)) {
                    storeFilterContent = {}
                    for (let k in vals) {//eslint-disable-line
                        if (vals[k] !== undefined) {
                            if (k === 'addTime' && vals[k] !== '') {
                                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
                            } else {
                                storeFilterContent[k] = vals[k]
                            }
                        }
                    }
                    setFilterModelvisible(true)
                } else {
                    message.error('请选择筛选内容')
                }
            }
        })
    }

    //保存筛选点击确定
    const filterHandleOk = () => {
        if (filterInfoValue.trim() === '') {
            message.error('筛选名称不能为空')
            return
        }
        api.saveSearchStorage({
            name: filterInfoValue,
            key: location.pathname,
            content: storeFilterContent
        }).then(() => {
            setFilterModelvisible(false)
            message.success('添加成功')
        })

    }

    //取消筛选点击确定
    const filterHandleCancel = () => {
        setFilterModelvisible(false)
    }

    //修改筛选名称
    const onFilterInfoChange = (e) => {
        setFilterInfoValue(e.target.value)
    }

    //导出
    const onExportFile = () => {
        setShowExport(true)
        let {limit, page, ...setting} = searchParamsTmp
        setinstallData(setting)
        allFilter = Object.assign({}, searchParamsTmp)
        for (let i in allFilter) {
            if (i == 'page' || i == 'limit') delete allFilter[i]
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            //console.log(selectedRowKeys, selectedRows)
            setChecked(selectedRowKeys.length)
            selectedIds = selectedRowKeys
        },
        onSelect: (record, selected, selectedRows) => {
            // console.log(record, selected, selectedRows)
            if (!selected) {
                for (let i = 0; i < checkedIdArr.length; i++) {
                    if (checkedIdArr[i] == record.id) {//eslint-disable-line
                        checkedIdArr.splice(i, 1)
                        i--
                    }
                }
            } else {
                checkedIdArr.push(record.id)
            }
            let arrNew = [...new Set(checkedIdArr)]
            setCheckedIdArr(arrNew)
            setChecked(arrNew.length)
            selectedIds = arrNew
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
        //全选一列（默认十条）
        onSelectAll: (selected, selectedRows, changeRows) => {
            //console.log(selected, selectedRows, changeRows)
            let arr = []
            changeRows.forEach(item => {
                arr.push(item.id)
            })
            if (selected) {
                setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
            } else {
                setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
            }
        },
        selectedRowKeys: checkedIdArr
    }

    //反选
    const onReverse = () => {
        setShowLoading(true)
        setTimeout(() => {
            let IDS = JSON.parse(localStorage.getItem('ids'))
            let arr = []
            for (let i = 0; i < IDS.length; i++) {
                arr[i] = IDS[i]
            }
            let filterIds = []
            arr.forEach(item => {
                if (!checkedIdArr.includes(item)) {
                    filterIds.push(item)
                }
            })
            setCheckedIdArr(filterIds)
            setChecked(filterIds.length)
            selectedIds = filterIds
            setShowLoading(false)
        }, 500)
    }

    //全选
    const onAllelection = () => {
        let IDS = JSON.parse(localStorage.getItem('ids'))
        let arr = []
        for (let i = 0; i < IDS.length; i++) {
            arr[i] = IDS[i]
        }
        setChecked(arr.length)
        setCheckedIdArr(arr)
        //console.log(arr)
        selectedIds = arr
    }

    // 厂商行业导入
    const onImportVendor = () => {
        ImportRefVendor.current.onRemoved()
        setShowVendorImport(true)
    }

    // 厂商行业导入确定
    const onVendorImport = (fileList) => {
        if (fileList[0].status !== 'removed') {
            api.setVendorImportExcel({
                uploadUrl: fileList[0].url
            }).then(res => {
                getindustryConsumList()
                getindustryCountTotal()
                message.success('导入成功')
                ImportRefVendor.current.onLoading()
                setShowVendorImport(false)
            }).catch(res => {
                // console.log('导入', res)
                ImportRefVendor.current.onLoading()
            })
        } else {
            message.error('请导入模板')
        }
    }

    return (
        <Spin spinning={showLoading}>
            <CorpMore onChange={onChangeCorp} />
            <div className="search-askforleave-wrap">
                <h4 className="title">其它选项：</h4>
                {
                    !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
                        : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
                }
                <Form onSubmit={searchSubmit} {...formItemLayout}>
                    <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
                        <FormItem label="一级行业">
                            {getFieldDecorator('industry1', { initialValue: '0' })(<Select placeholder="全部"
                                dropdownMatchSelectWidth={false}
                                onSelect={(val) => onSelectIndustry(val)}
                                showSearch={true}
                                optionFilterProp="children"
                            >
                                {selectData.industry1Arr && selectData.industry1Arr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                            </Select>
                            )}
                        </FormItem>
                        <FormItem label="二级行业">
                            {getFieldDecorator('industry2', { initialValue: '0' })
                                (<Select placeholder="全部"
                                    dropdownMatchSelectWidth={false}
                                    onChange={(val) => onIndustryTwo(val)}
                                    showSearch={true}
                                    optionFilterProp="children"
                                >
                                    {selectData.industry2Arr && selectData.industry2Arr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                                </Select>
                                )}
                        </FormItem>
                        <FormItem label="订单生效时间" style={{ width: '40%' }}>
                            {getFieldDecorator('addTime', { initialValue: '' })(<RangePicker allowClear={false} />)}
                        </FormItem>
                        <FormItem label="项目">
                            {getFieldDecorator('projectId', { initialValue: '0' })(<Select
                                placeholder="全部"
                                dropdownMatchSelectWidth={false}
                                showSearch
                                optionFilterProp="children"
                            >
                                {
                                    selectData.projectArr && selectData.projectArr.map((item, index) => {
                                        return <Option key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>)}
                        </FormItem>
                        <FormItem label="数据维度">
                            {getFieldDecorator('dataDimension', { initialValue: '0' })(<Select
                                placeholder="全部"
                                showSearch
                                optionFilterProp="children"
                            >
                                {
                                    dimensionArr && dimensionArr.map((item, index) => {
                                        return <Option key={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>)}
                        </FormItem>
                    </div>
                    <FormItem className="btns" label=" " colon={false}>
                        <div className="staff-filter-btns">
                            {showAll && <>
                                <h4 className="filter-title">已保存筛选方案：</h4>
                                <div className="filter-select">
                                    <Select style={{ display: 'inline-block', width: '160px' }} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}>
                                        {filterContent.map((item, index) =>
                                            <Option value={index} key={index}>
                                                <div className="closeStyBtn">
                                                    {item.name}
                                                    {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                                                        e.stopPropagation()
                                                        onSearchDelete(item.id)
                                                    }} /></span> : null}
                                                </div>
                                            </Option>
                                        )}
                                    </Select>
                                </div>
                                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
                            </>}
                            <Button type="primary" className="add-projectMgt-btn" onClick={onImportVendor}>厂商行业导入</Button>
                            <Auth auths={authList} code="export">
                                <Button type="primary" icon="upload" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
                            </Auth>
                            <Button onClick={onReset}>重置</Button>
                            <Button type="primary" htmlType="submit" disabled={listFlag || numFlag}>筛选</Button>
                            <Modal
                                title="保存筛选条件"
                                visible={filterModelvisible}
                                onOk={filterHandleOk}
                                onCancel={filterHandleCancel}
                            >
                                <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={onFilterInfoChange} />
                            </Modal>
                        </div>
                    </FormItem>
                </Form>
            </div>
            <div className="line"></div>
            <Export
                show={showExport}
                onCancel={() => {
                    setShowExport(false)
                    productsRef.current.changeVal()
                }}
                tplUrl='getIndustrytionChangeExportTpl'
                fieldsUrl='getIndustrytionChangeTplItems'
                saveUrl='saveIndustrytionChangeExportTpls'
                exportUrl='exportIndustrytionChange'
                screen={installData}
                method="Export"
                parame={{ ExportType: 'IndustryConsumption.list', id: selectedIds, ...allFilter }}
                // type={1}
                cRef={productsRef}
            />
            {/* 厂商行业导入 */}
            <Import
                onSearch={onVendorImport}
                visibleFile={showVendorImport}
                onCloseImport={() => setShowVendorImport(false)}
                ImportTemplate="/api/File/DownTemplate?url=/templates/supplierIndustryImport.xlsx&name=厂商行业导入模板"
                ImportRef={ImportRefVendor}
            />
            <Spin spinning={numFlag}>
                <Alert style={{ marginTop: 18 }} message={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                            {/* <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
                            {checked !== 0 &&
                                <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
                            {checked === 0 && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
                            <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }}
                                onClick={onAllelection}>全选</span> */}
                        </div>
                        <div>
                            <span>{`数据总计: ${count}条数据`}</span> &nbsp;&nbsp;&nbsp;
                            <span>{`直销总消耗: ${totalNum && totalNum.directTotalConsumption ? totalNum.directTotalConsumption : ''}`}</span> &nbsp;&nbsp;&nbsp;
                            <span>{`渠道总消耗: ${totalNum && totalNum.agentTotalConsumption ? totalNum.agentTotalConsumption : ''}`}</span> &nbsp;&nbsp;&nbsp;
                            <span>{`总消耗: ${totalNum && totalNum.totalConsumption ? totalNum.totalConsumption : ''}`}</span> &nbsp;&nbsp;
                        </div>
                    </div>
                } type="info" showIcon />
            </Spin>
            <Table
                size="small"
                dataSource={list}
                rowKey={(record, i) => i}
                loading={loading}
                className="askforleave-table"
                pagination={{
                    pageSize,
                    total: count,
                    current: currentPage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                }}
                onChange={onChangeTable}
                scroll={{ x: 'max-content' }}
                // rowSelection={rowSelection}
            >
                {/* <Column title="一级行业" dataIndex="industry1Name" fixed="left" width={120} />
                <Column title="二级行业" dataIndex="industry2Name" fixed="left" width={180} /> */}
                {
                    columnVal.map((item, index) =>
                        <Column title={item.title}
                            key={index}
                            dataIndex={item.col}
                            fixed={item.col == 'industry1Name' || item.col == 'industry2Name' ? "left" : ""}
                            width={item.col == 'industry1Name' || item.col == 'industry2Name' ? '140px' : ""} />
                    )}
            </Table>
        </Spin>
    )
}

export default Form.create()(IndustrySum)