/**
 * 模块名称: 代理商管理-资金列表
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from 'react'
import api from '@/api'
import {
    Form,
    Input,
    Row, Col, Select, Button, Table
} from 'antd'

const Column = Table.Column
const FormItem = Form.Item
let pageSize = 10
let currentPage = 1


let filter = {
    project: '',
    product: '',
    industryOne: '',
    industryTwo: '',
    discountType: '',
}

const Zhekou = (props) => {
    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)

    const {id} = props
    const {getFieldDecorator, validateFields, resetFields, getFieldsValue} = props.form

    useEffect(() => {
        filter = {
            project: '',
            product: '',
            industryOne: '',
            industryTwo: '',
            discountType: '',
        }
        getList()
    }, [])

    const getList = () => {
        // const vals = getFieldsValue([
        //     'project',
        //     'product',
        //     'industryOne',
        //     'industryTwo',
        //     'discountType',
        // ]);
        setLoading(true)
        api.agentGetAgentDiscountList({...filter, qId:id, limit: pageSize, page: currentPage}).then(
            res => {
                setList(res)
            }
        ).finally(() => {
            setLoading(false)
        })
    }


    // 查询
    const onSearch = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            if (!err) {
                if (vals.searchTime) {
                    delete vals.searchTime
                }
                filter = {...filter, ...vals}
                currentPage = 1
                getList()
            }
        })
    }

    const onChangeTable = (pagination) => {
        currentPage = pagination.current
        pageSize = pagination.pageSize
        getList()
    }

    const onReset = () => {
        resetFields()
        filter = {
            project: '',
            product: '',
            industryOne: '',
            industryTwo: '',
            discountType: '',
        }
        currentPage = 1
        getList()
    }

    const zhekouType = [
        {
            id:0,
            name:'新开'
        },
        {
            id:1,
            name:'续费'
        }
    ]

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        }
    }


    return (
        <div  className='agent-zhekou'>
            <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
                <div className="search-item-wrap">
                    <div className="search-item">
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem label="项目" labelCol={{span: 8}}>
                                    {getFieldDecorator('project')(<Input placeholder="请输入"/>)}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="产品" labelCol={{span: 8}}>
                                    {getFieldDecorator('product')(<Input placeholder="请输入"/>)}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="一级行业" labelCol={{span: 8}}>
                                    {getFieldDecorator('industryOne')(<Input placeholder="请输入"/>)}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItem label="二级行业" labelCol={{span: 8}}>
                                    {getFieldDecorator('industryTwo')(<Input placeholder="请输入"/>)}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="折扣类型" labelCol={{span: 8}}>
                                    {getFieldDecorator('discountType')(
                                        <Select placeholder="请选择">
                                            {
                                                zhekouType.map(item => <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="search-btns">
                    <Button onClick={onReset}>重置</Button>
                    <Button style={{marginLeft: 8}} type="primary" htmlType="submit">查询</Button>
                </div>
            </Form>
            <Table
                dataSource={list.list}
                rowKey="number"
                className="table-page"
                loading={loading}
                pagination={{
                    pageSize,
                    total: list.count,
                    current: currentPage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
                    showTotal: total => {
                        let totalPages = Math.ceil(total/pageSize)
                        return `共 ${total} 条记录，第 ${currentPage}/${totalPages} 页`
                    }
                }}
                onChange={onChangeTable}
            >
                <Column title="序号" dataIndex="number"/>
                <Column title="折扣" dataIndex="discount"/>
                <Column title="项目" dataIndex="project"/>
                <Column title="产品" dataIndex="product"/>
                <Column title="一级行业" dataIndex="one_industry"/>
                <Column title="二级行业" dataIndex="two_industry"/>
                <Column title="折扣类型" dataIndex="typeName" />
            </Table>


        </div>
    )
}

export default Form.create()(Zhekou)