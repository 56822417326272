/*
 * @Author: zhuyan 
 * 2022新业务关联 => 编辑
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Form,
  Input,
  message,
  Button
} from 'antd'

const FormItem = Form.Item

const EditBiz = (props) => {
  const { getFieldDecorator, validateFields, setFieldsValue, getFieldValue } = props.form
  const { setBizVisible, getList, setBizLoading, editId, getSelectData } = props
  const [saveLoading, setSaveLoading] = useState(false)
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    onEdit()
  }, [])


  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  // 编辑框
  const onEdit = () => {
    setBizLoading(true)
    api.getNewBusinessSearchInfo({ id: editId }).then(res => {
      setBizLoading(false)
      const { oneLevel, twoLevel, threeLevel } = res
      setDetail(res)
      setFieldsValue({
        oneLevel: oneLevel,
        twoLevel: twoLevel,
        threeLevel: threeLevel
      })
    }).catch(() => {
      setBizLoading(false)
    })
  }

  // 保存
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        if (editId) {
          vals.id = editId
        }
        setSaveLoading(true)
        let params = { ...vals }
        api.setNewBusinessAssocEdit(params)
          .then(() => {
            setSaveLoading(false)
            setBizVisible(false)
            message.success('保存成功')
            getList()
            getSelectData()
          })
          .catch(() => setSaveLoading(false))
      }
    })
  }

  const onChangeOneLevel = () => {
    setFieldsValue({
      twoLevel: '',
      threeLevel: ''
    })
  }

  const onChangeTwoLevel = () => {
    setFieldsValue({
      threeLevel: ''
    })
  }

  return (
    <div className="setIndustry">
      <Form onSubmit={formSubmit}  {...formItemLayout}>
        <FormItem label="项目">
          <span>{detail && detail.projectName}</span>
        </FormItem>
        <FormItem label="产品">
          <span>{detail && detail.productName}</span>
        </FormItem>
        <FormItem label="新业务一级">
          {getFieldDecorator('oneLevel', { initialValue: '' })(
            <Input placeholder="请输入新业务一级" onChange={onChangeOneLevel} />
          )}
        </FormItem>
        <FormItem label="新业务二级">
          {getFieldDecorator('twoLevel', { initialValue: '' })(
            <Input placeholder="请输入新业务二级" onChange={onChangeTwoLevel} disabled={getFieldValue('oneLevel').trim() == '' ? true : false} />
          )}
        </FormItem>
        <FormItem label="新业务三级">
          {getFieldDecorator('threeLevel', { initialValue: '' })(
            <Input placeholder="请输入新业务三级" disabled={getFieldValue('twoLevel').trim() == '' ? true : false} />
          )}
        </FormItem>
        <div style={{ height: 60 }}></div>
        <div className="setShortCloseBtn">
          <Button onClick={() => { setBizVisible(false) }} style={{ marginRight: '15px' }}>取消</Button>
          <Button type="primary" htmlType="submit" loading={saveLoading}>确定</Button>
        </div>
      </Form>
    </div>
  )
}

export default Form.create()(EditBiz)
