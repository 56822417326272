/**
 * 模块名称: 2022新业务关联
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Modal,
  message,
  Alert,
  Spin,
  Drawer
} from 'antd'
import { parseSearch } from 'utils'
import Export from 'components/Export'
import Auth from '@/components/AuthMiddleware'
import Import from 'components/Import'
import EditBiz from './module/EditBiz'

let selectedIds = ''
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option

let pageSize = 10
let currentPage = 1
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
}
let storeFilterContent = {}
let allFilter = {}
let allData = [{
  id: '0',
  name: '全部'
}]
let allDataval = ['全部']

const NewBiz = (props) => {
  const { history, match, location } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = props.form
  const productsRef = useRef()
  const ImportRefVendor = useRef()
  const [filterName, setFilterName] = useState(undefined)
  const [count, setCount] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [showExport, setShowExport] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [authList, setAuthList] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const search = parseSearch(location.search)
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [checked, setChecked] = useState(0)
  const [showLoading, setShowLoading] = useState(false)
  const [selectData, setSelectData] = useState({
    projectArr: [], // 项目
    productArr: [],  // 产品
    oneLevelArr: [], // 一级
    twoLevelArr: [], // 二级
    threeLevelArr: [], // 三级
    isRequest: false
  })
  const [selectInitData, setSelectInitData] = useState({})
  const [showNewBusinessImport, setshowNewBusinessImport] = useState(false) // 导入
  const [bizVisibl, setBizVisible] = useState(false)
  const [bizLoading, setBizLoading] = useState(false)
  const [editId, setEditId] = useState(null)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
    }
    storeFilterContent = {}
    getSelectData()
    getNewBusinessAssoclist()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
    }
    resetFields()
    setFilterName(undefined)
    setStatusValName(undefined)
    getNewBusinessAssoclist()
    setCheckedIdArr([])
    setChecked(0)
    getSelectData()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getNewBusinessAssoclist()
  }

  //获取列表
  const getNewBusinessAssoclist = () => {
    setList([])
    setLoading(true)
    api.getNewBusinessAssoclist(searchParamsTmp).then(res => {
      setCount(res.list.count)
      setList(res.list.list)
      if (currentPage === 1) {
        if (res.ids) {
          localStorage.setItem('ids', JSON.stringify(res.ids))
        } else {
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  //获取筛选项
  const getSelectData = () => {
    return Promise.all([
      api.getNewBusinessSearchList(),
    ]).then(list => {
      setSelectInitData(list[0])
      const { projectList, productList, oneLevelList, twoLevelList, threeLevelList } = list[0]
      setSelectData({
        projectArr: [...allData, ...projectList],
        productArr: [...allData, ...productList],
        oneLevelArr: [...allDataval, ...oneLevelList],
        twoLevelArr: [...allDataval, ...twoLevelList],
        threeLevelArr: [...allDataval, ...threeLevelList],
        isRequest: true
      })
      return list
    }).catch()
  }

  // 选择项目
  const onSelectProject = (val) => {
    setFieldsValue({
      productId: '0'
    })
  }

  // 下拉产品
  const onDropdownProductId = () => {
    if (getFieldValue('projectId') == '0') {
      const { productList } = selectInitData
      setSelectData({
        ...selectData,
        productArr: [...allData, ...productList],
      })
    } else {
      api.getProjectOrProduct({ 
        open: 0,
        projectId: getFieldValue('projectId'),
      }).then(data => {
        setSelectData({
          ...selectData,
          productArr: [...allData, ...data],
        })
      })
    }
  }

  // 选择一级
  const onSelectOneLevel = (val) => {
    setFieldsValue({
      twoLevel: '全部',
      threeLevel: '全部'
    })
  }

  const onSelectTwoLevel = () => {
    setFieldsValue({
      threeLevel: '全部'
    })
  }

  // 选择二级
  const onDropdownTwoLevel = () => {
    if (getFieldValue('oneLevel') == '全部') {
      const { twoLevelList, threeLevelList } = selectInitData
      setSelectData({
        ...selectData,
        twoLevelArr: [...allDataval, ...twoLevelList],
        threeLevelArr: [...allDataval, ...threeLevelList]
      })
    } else {
      api.getNewBusinessSearchLeveList({
        oneLevel: getFieldValue('oneLevel'),
        twoLevel: ''
      }).then(data => {
        setSelectData({
          ...selectData,
          twoLevelArr: [...allDataval, ...data.twoLevelList],
          threeLevelArr: [...allDataval, ...data.threeLevelList]
        })
      })
    }
  }

  const onDropdownThreeLevel = () => {
    if (getFieldValue('twoLevel') == '全部' && getFieldValue('oneLevel') == '全部') {
      const { threeLevelList } = selectInitData
      setSelectData({
        ...selectData,
        threeLevelArr: [...allDataval, ...threeLevelList]
      })
    } else {
      api.getNewBusinessSearchLeveList({
        oneLevel: getFieldValue('oneLevel') == '全部' ? '' : getFieldValue('oneLevel'),
        twoLevel: getFieldValue('twoLevel') == '全部' ? '' : getFieldValue('twoLevel')
      }).then(data => {
        setSelectData({
          ...selectData,
          threeLevelArr: [...allDataval, ...data.threeLevelList]
        })
      })
    }
  }

  //数据转换
  const transData = (vals) => {
    if (vals !== undefined) {
      for (let i in vals) {
        if (vals[i] !== undefined) {
          if (i == 'addTime') {
            if (vals[i] == '') {
              searchParamsTmp.orderStartDate = '0'
              searchParamsTmp.orderEndDate = '0'
            } else {
              searchParamsTmp.orderStartDate = moment(vals[i][0]).format('YYYY-MM-DD')
              searchParamsTmp.orderEndDate = moment(vals[i][1]).format('YYYY-MM-DD')
            }
          } else if (i == 'month') {
            searchParamsTmp.month = moment(vals[i]).format('YYYY年MM月')
          } else {
            if (vals[i] == '0' || vals[i] == '全部') {
              searchParamsTmp[i] = ''
            } else {
              searchParamsTmp[i] = vals[i]
            }
          }
        }
      }
      // console.log('searchParamsTmp=>', searchParamsTmp)
    }
  }

  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      console.log(vals)
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getNewBusinessAssoclist()
        setCheckedIdArr([])
        setChecked(0)
      }
    })
  }

  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(res => {
      setFilterContent(res.list)
    })
  }

  //选择方案
  const onFilterChecked = (item, val2) => {
    // console.log('item=>', item)
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[item].content
    searchParamsTmp = { ...searchParamsTmp, ...data }
    setFieldsValue({
      projectId: data.projectId ? data.projectId : undefined,
      productId: data.productId ? data.productId : undefined,
      oneLevel: data.oneLevel ? data.oneLevel : undefined,
      twoLevel: data.twoLevel ? data.twoLevel : undefined,
      threeLevel: data.threeLevel ? data.threeLevel : undefined,
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime' && vals[k] !== '') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }

  //保存筛选点击确定
  const filterHandleOk = () => {
    if (filterInfoValue.trim() === '') {
      message.error('筛选名称不能为空')
      return
    }
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: storeFilterContent
    }).then(() => {
      setFilterModelvisible(false)
      message.success('添加成功')
    })

  }

  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }

  //修改筛选名称
  const onFilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value)
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    allFilter = Object.assign({}, searchParamsTmp)
    for (let i in allFilter) {
      if (i == 'page' || i == 'limit') delete allFilter[i]
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      setChecked(selectedRowKeys.length)
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
    },
    selectedRowKeys: checkedIdArr
  }

  //反选
  const onReverse = () => {
    setShowLoading(true)
    setTimeout(() => {
      let IDS = JSON.parse(localStorage.getItem('ids'))
      let arr = []
      for (let i = 0; i < IDS.length; i++) {
        arr[i] = IDS[i]
      }
      let filterIds = []
      arr.forEach(item => {
        if (!checkedIdArr.includes(item)) {
          filterIds.push(item)
        }
      })
      setCheckedIdArr(filterIds)
      setChecked(filterIds.length)
      selectedIds = filterIds
      setShowLoading(false)
    }, 500)
  }

  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i]
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    selectedIds = arr
  }

  // 导入
  const onImportVendor = () => {
    ImportRefVendor.current.onRemoved()
    setshowNewBusinessImport(true)
  }

  // 导入确定
  const onNewBusinessImport = (fileList) => {
    if (fileList[0].status !== 'removed') {
      api.getNewBusinessAssocImport({
        relative: fileList[0].url
      }).then(res => {
        getNewBusinessAssoclist()
        message.success('导入成功')
        ImportRefVendor.current.onLoading()
        setshowNewBusinessImport(false)
        onReset()
      }).catch(res => {
        // console.log('导入', res)
        ImportRefVendor.current.onLoading()
      })
    } else {
      message.error('请导入模板')
    }
  }

  const onEdit = (val) => {
    setBizVisible(true)
    setEditId(val.id)
  }

  return (
    <Spin spinning={showLoading}>
      <div className="search-askforleave-wrap">
        <span className="title">&nbsp;</span>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
            : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="项目">
              {getFieldDecorator('projectId', { initialValue: '0' })(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
                onSelect={(val) => onSelectProject(val)}
                showSearch
                optionFilterProp="children"
              >
                {
                  selectData.projectArr && selectData.projectArr.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="产品">
              {getFieldDecorator('productId', { initialValue: '0' })(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
                onDropdownVisibleChange={() => onDropdownProductId()}
                showSearch
                optionFilterProp="children"
              >
                {
                  selectData.productArr && selectData.productArr.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="新业务一级">
              {getFieldDecorator('oneLevel', { initialValue: '全部' })(<Select placeholder="全部"
                dropdownMatchSelectWidth={false}
                // onDropdownVisibleChange = {() => onDropdownOneLevel()}
                onChange={(val) => onSelectOneLevel(val)}
                showSearch={true}
                optionFilterProp="children"
              >
                {selectData.oneLevelArr && selectData.oneLevelArr.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem label="新业务二级">
              {getFieldDecorator('twoLevel', { initialValue: '全部' })
                (<Select placeholder="全部"
                  dropdownMatchSelectWidth={false}
                  onDropdownVisibleChange={() => onDropdownTwoLevel()}
                  onChange={(val) => onSelectTwoLevel(val)}
                  showSearch={true}
                  optionFilterProp="children"
                >
                  {selectData.twoLevelArr && selectData.twoLevelArr.map((item, index) => <Option key={index} value={item}>{item}</Option>)}

                </Select>
                )}
            </FormItem>
            <FormItem label="新业务三级">
              {getFieldDecorator('threeLevel', { initialValue: '全部' })(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
                onDropdownVisibleChange={() => onDropdownThreeLevel()}
                showSearch
                optionFilterProp="children"
              >
                {selectData.threeLevelArr && selectData.threeLevelArr.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
              </Select>)}
            </FormItem>

          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{ display: 'inline-block', width: '160px' }} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}>
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Auth auths={authList} code="import">
                <Button type="primary" className="add-projectMgt-btn" onClick={onImportVendor}>导入</Button>
              </Auth>
              <Auth auths={authList} code="export">
                <Button type="primary" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
              </Auth>
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit">筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={onFilterInfoChange} />
              </Modal>
            </div>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl='getNewBusinessAssocExportTpl'
        fieldsUrl='getNewBusinessAssocTplItems'
        saveUrl='saveNewBusinessAssocExportTpls'
        exportUrl='exportNewBusinessAssoc'
        method="export"
        parame={{ ExportType: 'newbusinessassoc.export', id: selectedIds, ...allFilter }}
        // type={1}
        cRef={productsRef}
      />
      {/* 导入 */}
      <Import
        onSearch={onNewBusinessImport}
        visibleFile={showNewBusinessImport}
        onCloseImport={() => setshowNewBusinessImport(false)}
        ImportTemplate="/api/File/DownTemplate?url=/templates/newBusinessAssoc.xlsx&name=2022新业务导入模板"
        ImportRef={ImportRefVendor}
      />
      <Alert style={{ marginTop: 18 }} message={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
            {checked !== 0 &&
              <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
            {checked === 0 && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
            <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }}
              onClick={onAllelection}>全选</span>
          </div>
          <div>
            <span>{`数据总计: ${count}条数据`}</span>
          </div>
        </div>
      } type="info" showIcon />
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 'max-content' }}
        rowSelection={rowSelection}
      >
        <Column title="项目" dataIndex="projectName" />
        <Column title="产品" dataIndex="productName" />
        <Column title="新业务一级" dataIndex="oneLevel" />
        <Column title="新业务二级" dataIndex="twoLevel" />
        <Column title="新业务三级" dataIndex="threeLevel" />
        <Column title="操作"
          dataIndex="set"
          render={(name, record) => 
            <Auth auths={authList} code="edit">
               <a onClick={() => onEdit(record)}>修改</a>
            </Auth>
        }
        />
      </Table>
      <Drawer
        title="2022新业务关联"
        width={640}
        onClose={() => setBizVisible(false)}
        visible={bizVisibl}
        destroyOnClose={true}
      >
        <Spin spinning={bizLoading}>
          <EditBiz
            setBizVisible={setBizVisible}
            setBizLoading={setBizLoading}
            editId={editId}
            getList={getNewBusinessAssoclist}
            getSelectData={getSelectData}
          />
        </Spin>
      </Drawer>
    </Spin>
  )
}

export default Form.create()(NewBiz)