/**
 * 模块名称: 代理商客户联系人
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from 'react'
import api from '@/api'
import {
    Form,
    Input,
    message, Drawer
} from 'antd'
import BtnGroup from "../../../components/BtnGroup";

const FormItem = Form.Item

const ClientLianxiren = (props) => {

    const form = props.form;
    const { getFieldDecorator, validateFields, setFieldsValue} = form
    const { id, lianxiren, refrashList, visible, setVisible} = props

    const [submitLoading, setSubmitLoading] = useState(false)

    useEffect(() => {
        if(lianxiren.name !== undefined){
            setFieldsValue({
                name:lianxiren.name,
                mobile:lianxiren.mobile,
                mail:lianxiren.mail,
                tel:lianxiren.tel,
                fax:lianxiren.fax,
                qq:lianxiren.qq
            })
        }else{
            setFieldsValue({
                name:'',
                mobile:'',
                mail:'',
                tel:'',
                fax:'',
                qq:''
            })
        }
    }, [lianxiren])

    const onOk = () => {
        validateFields((err, vals) => {
            if (!err) {
                let submitVals = vals
                submitVals.clientId = id
                submitVals.id = lianxiren !== null ? lianxiren.id : 0

                setSubmitLoading(true)
                api.agentClientHandleClientContact(submitVals).then(
                    res => {
                        message.success("联系人保存成功")
                        refrashList()
                        setVisible(false)
                    }
                ).finally(() => setSubmitLoading(false))

            } else {
                for (let i in err) {
                    message.error(err[i].errors[0].message)
                }
            }


        })
    }

    const onCancel = () => {
        setVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        }
    }
    return (
        <Drawer
            title="联系人信息"
            visible={visible}
            width={600}
            onClose={() => setVisible(false)}
        >
            <Form  {...formItemLayout}>
            <FormItem label="联系人姓名" >
                {getFieldDecorator('name',{
                    rules: [{required: true, message: '请输入联系人姓名'}]
                })(
                    <Input placeholder="请输入"/>
                )}
            </FormItem>
            <FormItem label="手机号码" >
                {getFieldDecorator('mobile',{
                    rules: [{required: true, message: '请输入手机号码'}]
                })(
                    <Input placeholder="请输入"/>
                )}
            </FormItem>
            <FormItem label="邮箱" >
                {getFieldDecorator('mail')(
                    <Input placeholder="请输入"/>
                )}
            </FormItem>
            <FormItem label="座机" >
                {getFieldDecorator('tel')(
                    <Input placeholder="请输入，格式：xxx-xxxxxxx"/>
                )}
            </FormItem>
            <FormItem label="传真" >
                {getFieldDecorator('fax')(
                    <Input placeholder="请输入，格式：xxx-xxxxxxx"/>
                )}
            </FormItem>
            <FormItem label="QQ" >
                {getFieldDecorator('qq')(
                    <Input placeholder="请输入"/>
                )}
            </FormItem>
            <BtnGroup onCancel={onCancel} loading={submitLoading} onConfirm={onOk} />
            </Form>
        </Drawer>
    )
}

export default Form.create()(ClientLianxiren)