/**
 * 模块名称: 不知道谁写的，也不留个名字
 */

import React, {useEffect, useState} from "react";
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    DatePicker,
    Descriptions,
    Table,
    Spin,
    Drawer,
    Icon,
    Dropdown,
    Menu, Switch
} from "antd";
import { Link } from 'react-router-dom'

import api from '@/api'
import XuQian from "./xuqian";
import Lianxiren from "./lianxiren";
import moment from 'moment'
import Kehu from "./kehu";
import XinYongEDuTiaoZheng from "./xinyongedutiaozheng";

const {Column} = Table
let kehuFilter = {
  name: '',
  sTime: '',
  eTime: ''
}

let pageSize = 10
let currentPage = 1

let lianxirenPageSize = 10, kehuPageSize = 10
let lianxirenCurrentPage = 1, kehuCurrentPage=1
const dateFormat = 'YYYY-MM-DD'

const DetailPage = (props) => {
    const {id, form, history} = props
    const { getFieldDecorator, getFieldsValue, validateFields, resetFields, setFieldsValue } = form
    const [detailLoading, setDetailLoading] = useState(false)
    const [detail, setDetail] = useState({})
    const [xuQianShow, setXuQianShow] = useState(false)
    const [hetongVsible, setHetongVsible] = useState(false)
    const [hetongList, setHetongList] = useState([])
    const [jifenVsible, setJifenVsible] = useState(false)
    const [jifenList, setJifenList] = useState([])
    const [lianxirenList, setLianxirenList] = useState([])
    const [lianxiren, setLianxiren] = useState({})
    const [lianxirenShow, setLianxirenShow] = useState(false)
    const [kehuList, setKehuList] = useState([])
    const [kehu, setKehu] = useState({})
    const [kehuShow, setKehuShow] = useState(false)
    const [xinYongEDuTiaoZhengVisible, setXinYongEDuTiaoZhengVisible] = useState(false)
    const [ifExpand, setIfExpand] = useState(false)
    const [contractValid, setContractValid] = useState(false)
    const [count, setCount] = useState(0)

    useEffect(() => {
        kehuFilter = {
            name: '',
            sTime: '',
            eTime: ''
        }
        lianxirenPageSize = 10
        kehuPageSize = 10
        lianxirenCurrentPage = 1
        kehuCurrentPage=1
        pageSize = 10
        currentPage = 1
        getDetail(id)
        getLianxirenList()
        getKehuList()
    }, [])

    const getDetail = (id) => {
        setDetailLoading(true)
        api.agentGetDetail({qId: id}).then((res) => {
            //假数据
            /*res = {
                "id": 3,
                "name": "北京全时天",
                "verifyType": 1,
                "verifyNumber": "absd123aa213aaa",
                "creatorId": 15527,
                "creatorName": "岳先生",
                "corpId": 1,
                "status": 1,
                "addTime": "2020-06-15 16:25:48",
                "updateTime": "2020-07-15 11:41:27",
                "isDel": 0,
                "verifyTypeName": "事业单位",
                "contract": "1111111,321",
                "account": [{
                    "fundId": 18,
                    "creditId": "",
                    "accountId": 5,
                    "accountName": "天地在线天津",
                    "balance": "1.00",
                    "changqi": "",
                    "changqiUsed": "",
                    "linshi": "",
                    "linshiUsed": ""
                }, {
                    "fundId": "",
                    "creditId": 1,
                    "accountId": 8,
                    "accountName": "天地在线",
                    "balance": "",
                    "changqi": "400.00",
                    "changqiUsed": "200.00",
                    "linshi": "39.00",
                    "linshiUsed": "10.00"
                }]
            }*/
            if(res.account){
                let colors = ['#f50', '#2db7f5', '#87d068', '#108ee9']
                res.account.forEach((item, index, self) => {
                    self[index].accountColor = `${colors[Math.floor(Math.random() * 4)]}`
                })
            }
            setDetailLoading(false)
            setDetail(res)
        })
    }

    const getLianxirenList = () => {
        api.agentContactGetList({qId:id, limit: global.paramsLimit}).then(res => {
            if(res.list.length && res.list.length > 2){
                setIfExpand(true)
            }else{
                setIfExpand(false)
            }
            setLianxirenList(res)
        })
    }

    const onChangeTableLianxiren = (pagination) => {
        lianxirenCurrentPage = pagination.current
        lianxirenPageSize = pagination.pageSize
        getLianxirenList()
    }

    const onChangeTable = (pagination) => {
        console.log(pagination)
        pageSize = pagination.pageSize
        currentPage = pagination.current
        showJifen()
    }

    const getKehuList = () => {
        kehuFilter.qId = id
        api.agentClientGetList({...kehuFilter, limit: kehuPageSize, page: kehuCurrentPage}).then(res => {
            setKehuList(res)
        })
    }

    const onChangeTableKehu = (pagination) => {
        kehuCurrentPage = pagination.current
        kehuPageSize = pagination.pageSize
        getKehuList()
    }

    const showXuQian = () => {
        setXuQianShow(true)
    }


    const showHetong = (contract,valid = undefined) => {
        if(contract){

            if(valid == undefined || valid == false){
                setContractValid(false)
            }
            else{
                setContractValid(true)
            }
            valid = valid == true ? 1 : 0
            api.agentGetContractList({contract,valid:valid})
                .then((res) => {
                    setHetongList(res.list)
                    setHetongVsible(true)
                })
        }else{
            setHetongList([])
            setHetongVsible(true)
        }

    }

    const showJifen = () => {
        api.agentGetIntegralList({limit: pageSize, page: currentPage, qId: id})
            .then((res) => {
                setJifenVsible(true)
                setJifenList(res.list)
                setCount(res.count)
            })
    }

    const ZhuTi = (props) => {
        const {zhuti} = props
        return (
            <Row className={'zhuti-row'}>
                <Col span={3} className={'zhuti-title'}>
                    <span style={{backgroundColor: zhuti.accountColor, padding: '4px', color: '#fff', borderRadius: '4px'}}>{zhuti.accountName}</span>
                </Col>
                <Col span={3}>
                    <Descriptions layout='vertical'>
                        <Descriptions.Item label={'正常账户'}>
                            {zhuti.balance}{zhuti.balance !== '' ? '元' : null}
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={7}>
                    <Descriptions layout='vertical'>
                        <Descriptions.Item label='信用账户（长期）'>
                            <Link to={`/account/credit?id=${+id}&type=${1}&accountType=${0}&accountId=${zhuti.accountId}`}>{zhuti.changqi}{zhuti.changqi !== '' ? '元' : null}</Link>
                            <span style={{color: 'red', fontWeight: 500, fontSize:'18px'}}>{zhuti.changqiUsed !== '' ? `(已用${zhuti.changqiUsed}元)` : null}</span>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={7}>
                    <Descriptions layout='vertical'>
                        <Descriptions.Item label={'信用账户（临时）'}>
                            <Link to={`/account/credit?id=${+id}&type=${1}&accountType=${1}&accountId=${zhuti.accountId}`}>{zhuti.linshi}{zhuti.linshi !== '' ? '元' : null}</Link>
                            <span style={{color: 'red', fontWeight: 500, fontSize:'18px'}}>{zhuti.linshiUsed !== '' ? `(已用${zhuti.linshiUsed}元)` : null}</span>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        )
    }

    const editLianxiren = (lianxiren)  => {
        setLianxiren(lianxiren)
        setLianxirenShow(true)
    }

    const editKehu = (kehu)  => {
        setKehu(kehu)
        setKehuShow(true)
    }

    const onSearchKehu = (e) =>{
        e.preventDefault()
        const vals = getFieldsValue(['searchKehuName','searchKehuTime'])
        if(vals.searchKehuName){
            kehuFilter.name = vals.searchKehuName
        }else{
            kehuFilter.name = ''
        }
        if(vals.searchKehuTime !== undefined && vals.searchKehuTime.length > 0){
            kehuFilter.sTime = moment(vals.searchKehuTime[0]).format(dateFormat)
            kehuFilter.eTime = moment(vals.searchKehuTime[1]).format(dateFormat)
        }else{
            kehuFilter.sTime = ''
            kehuFilter.eTime = ''
        }

        kehuCurrentPage = 1

        getKehuList()

    }

    const onResetKehu = () => {
        // setFieldsValue({searchKehuName: null, searchKehuTime:undefined})
        resetFields()
        kehuFilter = {
          name: '',
          sTime: '',
          eTime: ''
        }
        kehuCurrentPage = 1
        getKehuList()
    }


    const handleMenuClick = ({key}) => {

        switch (+key) {
            case 1://充积分
                history.push('/agent/integralManage/add/0?relationId='+id+'&bizType=0');
                break;
            case 2://退积分
                history.push('/agent/integralManage/add/1?relationId='+id+'&bizType=1');
                break;
            case 3://申请信用额度
                history.push('/account/creditapply/add?customerType=1&customerId='+id+'&accountType=');
                break;
        }
    }

    const menu = (<Menu onClick={handleMenuClick}>
        <Menu.Item key="1">
            充积分
        </Menu.Item>
        <Menu.Item key="2">
            退积分
        </Menu.Item>
        <Menu.Item key="3">
            申请信用额度
        </Menu.Item>
    </Menu>)

    const renkuan = () => {
        history.push('/fund/moneyClaim/detail?clientId='+id+'&clientType=1');
    }

    const zhuankuan = () => {
        history.push('/fund/moneyTransmit/add?clientType=1&clientId='+id);
    }

    // const tuikuan = () => {
    //     history.push('/fund/refundManage/add?relationType=1&relationId='+id);
    // }

    // 权签
    const handleMenuClickTk = (e) => {
        if (e.key == '1') {
            api.setRefundCheckApply({ refundType: 1 }).then(data => {
                history.push('/fund/refundManage/addNot?relationType=1&relationId='+id)
            })
        } else {
            api.setRefundCheckApply({ refundType: 0 }).then(data => {
                history.push('/fund/refundManage/add?relationType=1&relationId='+id)
            })
        }
    }

    const menuTk = (
        <Menu onClick={handleMenuClickTk}>
            <Menu.Item key='1'>
                未认款退款
            </Menu.Item>
            <Menu.Item key='2'>
                正常退款
            </Menu.Item>
        </Menu>
    )
    
    const kaifapiao = () => {
        history.push('/protocol/invoice/apply',{qId:id});
    }

    const kaishouju = () => {
        history.push('/protocol/quittance/apply?qId='+id);
    }

    const mingchengbiangeng = () => {
        history.push('/agent/nameManage/add?qId='+id);
    }

    const xinyongedutiaozheng = () => {
        setXinYongEDuTiaoZhengVisible(true)
    }

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        }
    }

    return (
        <>
            <Spin spinning={detailLoading}>
                <div className="agent-detail dailishang-detail">
                    <Row>
                        <Col span={6}>
                            <Descriptions style={{marginBottom: 0}}>
                                <Descriptions.Item label={'代理商名称'}>
                                    {detail.name}（QID：{id}）
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={6}>
                            [<a onClick={() => mingchengbiangeng()}>名称变更</a>]
                            [<a onClick={() => showXuQian()}>续签</a>]
                            [<a onClick={() => showHetong(detail.contract)}>合同</a>]
                            [<a onClick={() => showJifen()}>积分</a>]
                        </Col>
                        <Col span={12}>
                            <Button.Group>
                                <Button onClick={renkuan}>认款</Button>
                                <Button onClick={xinyongedutiaozheng}>信用调额</Button>
                                <Button onClick={zhuankuan}>转款</Button>
                                <Button onClick={kaifapiao}>开发票</Button>
                                <Button onClick={kaishouju}>开收据</Button>
                                {/* 权签 */}
                                <Dropdown overlay={menuTk} trigger={['click']}>
                                  <Button className="detailSty">退款</Button>
                                </Dropdown>
                                {/* <Button onClick={tuikuan}>退款</Button> */}
                            </Button.Group>
                            <Dropdown overlay={menu}>
                                <Button>
                                    更多操作 <Icon type="down"/>
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>

                    <div className="line"></div>
                    {detail.account !== undefined && detail.account.map(item => <ZhuTi key={item.accountId} zhuti={item}/>)}

                    <div className="line-box"></div>

                    <Descriptions title={
                        (
                            <>
                                <div className='title'>联系人信息</div>
                                <Button className="add-customerBtn" type="primary" onClick={() => editLianxiren({})}>新增</Button>
                            </>
                        )
                    } className="pro-detail-smailtitle lianxiren-title"></Descriptions>

                    <div className="lianxirenListBox" style={{height: ifExpand ? '210px' : 'auto'}}>
                        <Table
                            dataSource={lianxirenList.list}
                            rowKey="id"
                            className="table-page"
                            style={{marginBottom: 50, height: '100%'}}
                            // pagination={!ifExpand ? {
                            //     pageSize:lianxirenPageSize,
                            //     total: lianxirenList.count,
                            //     current: lianxirenCurrentPage,
                            //     showQuickJumper: true,
                            //     showSizeChanger: true,
                            //     showTotal: total => {
                            //         let totalPages = Math.ceil(total/lianxirenPageSize)
                            //         return `共 ${total} 条记录，第 ${lianxirenCurrentPage}/${totalPages} 页`
                            //     }
                            // } : false}
                            pagination={false}
                            onChange={onChangeTableLianxiren}
                        >
                            <Column title="序号" dataIndex="id" render={(text, record,index) => {
                                return index + 1
                            }} />
                            <Column title="联系人名称" dataIndex="name"/>
                            <Column title="手机号码" dataIndex="mobile"/>
                            <Column title="邮箱" dataIndex="mail"/>
                            <Column title="座机" dataIndex="tel"/>
                            <Column title="传真" dataIndex="fax"/>
                            <Column title="地址" dataIndex="address"/>
                            <Column title="创建人" dataIndex="creatorName"/>
                            <Column title="操作" render={(text, record) => {
                                return (
                                    <a onClick={() => editLianxiren(record)}>编辑</a>
                                )
                            }}/>
                        </Table>
                        {lianxirenList.count > 3 && <div className="shrink" style={{background: ifExpand ? '#fff' : 'none'}}><span style={{cursor: 'pointer'}} onClick={() => {
                            setIfExpand(!ifExpand)
                        }}>{ifExpand ? '展开' : '收起'} <Icon type={ifExpand ? "down" : "up"} /></span></div>}
                    </div>

                    <div className="line-box"></div>
                    <Descriptions title="客户列表" className="pro-detail-smailtitle"></Descriptions>
                    <Form onSubmit={onSearchKehu} {...formItemLayout} className="ant-advanced-search-formTwo">
                        <div className="search-item-wrap">
                            <div className="search-item">
                                <Row gutter={24} >
                                    <Col span={8}>
                                        <Form.Item label="搜索">
                                            {getFieldDecorator('searchKehuName', {initialValue: ''})(<Input placeholder="OAID/客户名称/厂商ID"/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label="创建时间">
                                            {getFieldDecorator('searchKehuTime', {
                                            })(<DatePicker.RangePicker />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <div className="search-btns">
                                            <Button onClick={onResetKehu}>重置</Button>
                                            <Button style={{marginLeft: 8}} type="primary" htmlType="submit">查询</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </Form>
                    <div className="add-customer">
                        {
                            <Button className="add-customerBtn" type="primary" onClick={() => editKehu({})}>新增</Button>
                        }

                    </div>
                    <Table
                        dataSource={kehuList.list}
                        rowKey="id"
                        className="table-page"
                        scroll={{x: 900}}
                        pagination={{
                            pageSize: kehuPageSize,
                            total: kehuList.count,
                            current: kehuCurrentPage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
                            showTotal: total => {
                                let totalPages = Math.ceil(total/kehuPageSize)
                                return `共 ${total} 条记录，第 ${kehuCurrentPage}/${totalPages} 页`
                            }
                        }}
                        onChange={onChangeTableKehu}
                    >
                        <Column title="OAID" dataIndex="id"/>
                        <Column title="客户名称" dataIndex="name" render={(text, record) => <Link to={"/agent/manage/client?id="+record.id+"&qId="+id}>{text}</Link>}/>
                        <Column title="厂商ID（个数）" dataIndex="firmNum"/>
                        <Column title="网址" dataIndex="website"/>
                        <Column title="一级行业" dataIndex="industry1Name"/>
                        <Column title="二级行业" dataIndex="industry2Name"/>
                        <Column title="创建时间" dataIndex="addTime"/>
                        <Column title="认证状态" dataIndex="statusName"/>
                        <Column title="负责人" dataIndex="principal" render={
                            text => text.map(item => item.principalName).join('，')
                        }/>
                    </Table>
                </div>
            </Spin>
            <XuQian setVisible={setXuQianShow} visible={xuQianShow} id={id}/>
            <Lianxiren setVisible={setLianxirenShow} refrashList={getLianxirenList} visible={lianxirenShow} qId={id} lianxiren={lianxiren} />
            <Kehu setVisible={setKehuShow} refrashList={getKehuList} visible={kehuShow} qId={id} kehu={kehu} />

            <Drawer
                title="合同明细记录表"
                width={1000}
                onClose={() => setHetongVsible(false)}
                visible={hetongVsible}
            >
                <div style={{'textAlign':'right'}}>
                    仅显示有效期内合同
                    <Switch checkedChildren="是" unCheckedChildren="否" checked={contractValid} onChange={(value)=>showHetong(detail.contract,value)} />
                </div>
                <Table
                    dataSource={hetongList}
                    rowKey="number"
                    className="table-page"
                    scroll={{x: 900}}
                    pagination={false}
                >
                    <Column title="序号" dataIndex="number"/>
                    <Column title="合同号" dataIndex="contractNo"/>
                    <Column title="关联主体" dataIndex="accountName"/>
                    <Column title="项目" dataIndex="projectNames"/>
                    <Column title="产品" dataIndex="productNames"/>
                    <Column title="合同领取人" dataIndex="receiver"/>
                    <Column title="负责人" dataIndex="leadCadreName"/>
                    <Column title="合同期限" render={(record) => {
                        return record.beginTime + ' ~ ' + record.endTime
                    }}/>
                </Table>
            </Drawer>

            <Drawer
                title="代理商可用积分查询"
                width={600}
                onClose={() => setJifenVsible(false)}
                visible={jifenVsible}
            >
                <Table
                    dataSource={jifenList}
                    rowKey="accountNo"
                    className="table-page"
                    scroll={{x: 500}}
                    pagination={{
                        pageSize: pageSize,
                        total: count,
                        current: currentPage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                    }}
                    onChange={onChangeTable}
                >
                    <Column title="序号" dataIndex="number"/>
                    <Column title="主体" dataIndex="companyName"/>
                    <Column title="项目" dataIndex="projectName"/>
                    <Column title="项目可用积分" dataIndex="integral"/>
                </Table>
            </Drawer>
            <XinYongEDuTiaoZheng history={history} id={id} zhutiList={detail.account === undefined ? [] : detail.account} visible={xinYongEDuTiaoZhengVisible} setVisible={setXinYongEDuTiaoZhengVisible}/>
        </>
    )

}

export default Form.create()(DetailPage)