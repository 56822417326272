/**
 * 模块名称: 代理商客户管理
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col, DatePicker, Descriptions, Dropdown,
    Form, Icon, Input, Menu, message, Row, Spin, Table,
    Tabs
} from 'antd'
import './assets/style.scss'
import api from '@/api'
import {parseSearch} from "../../utils";
import ClientLianxiren from "./components/clientLianxiren";
import moment from 'moment'

const {TabPane} = Tabs;
const {Column} = Table
const changShangeFilter = {}

let lianxirenPageSize = 10, changShangPageSize = 10, dingdanPageSize = 10
let lianxirenCurrentPage = 1, changShangCurrentPage = 1, dingdanCurrentPage = 1
const dateFormat = 'YYYY-MM-DD'
let selectedIds = ''

const Client = (props) => {

    const {location, history, form} = props
    const {getFieldDecorator, getFieldsValue, setFieldsValue} = form
    const search = parseSearch(location.search)
    const [detail, setDetail] = useState({})
    const [lianxirenList, setLianxirenList] = useState({})
    const [lianxiren, setLianxiren] = useState({})
    const [lianxirenShow, setLianxirenShow] = useState(false)
    const [detailLoading, setDetailLoading] = useState(false)
    const [changShangList, setChangShangList] = useState({})
    const [agentClientOrderList, setAgentClientOrderList] = useState({})
    const [checked, setChecked] = useState(0)
    const [checkedIdArr, setCheckedIdArr] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
       lianxirenPageSize = 10
       changShangPageSize = 10
       dingdanPageSize = 10
       lianxirenCurrentPage = 1
       changShangCurrentPage = 1
       dingdanCurrentPage = 1

        if(search.qId === undefined || !search.qId){
            message.error("缺少参数：qId")
            window.history.back(-1)
            return
        }

        if(search.id === undefined || !search.id){
            message.error("缺少参数：id")
            window.history.back(-1)
            return
        }

        setDetailLoading(true)
        api.getClientGetDetail({oaId: search.id}).then(res => {
            setDetail(res)
            setDetailLoading(false)
        })

        getLianxirenList()
        getChangShangeList()
        getAgentClientOrderList() // 订单
    }, [])

    const onChangeTableLianxiren = (pagination) => {
        lianxirenCurrentPage = pagination.current
        lianxirenPageSize = pagination.pageSize
        getLianxirenList()
    }

    const getLianxirenList = () => {
        api.agentClientGetContactList({
            oaId: search.id,
            limit: lianxirenPageSize,
            page: lianxirenCurrentPage
        }).then(res => {
            setLianxirenList(res)
        })
    }

    const editLianxiren = (lianxiren) => {
        setLianxiren(lianxiren)
        setLianxirenShow(true)
    }

    const onChangeTab = (key) => {
    }

    const newOrder = () => {
        //跳转新开订单页
        history.push('/orderAgent/add', {relationId: detail.id, qId:search.qId})
    }

    const getChangShangeList = () => {
        changShangeFilter.oaId = search.id
        setLoading(true)
        api.agentClientGetFirmList({
            ...changShangeFilter,
            qId:search.qId,
            limit: changShangPageSize,
            page: changShangCurrentPage
        }).then(res => {
            setChangShangList(res)
            setLoading(false)
        }).catch(() => setLoading(false))
    }

    // 订单
    const getAgentClientOrderList = () => {
        api.getAgentClientOrderList({
            qId:search.qId,
            clientId: search.id,
            limit: dingdanPageSize,
            page: dingdanCurrentPage
        }).then(res => {
            setAgentClientOrderList(res)
        })
    }

    const onSearchChangshang = (e) => {
        e.preventDefault()
        const vals = getFieldsValue(['searchChangShangName', 'searchChangShangTime'])
        if (vals.searchChangShangName) {
            changShangeFilter.name = vals.searchChangShangName
        } else {
            changShangeFilter.name = ''
        }
        if (vals.searchChangShangTime !== undefined && vals.searchChangShangTime.length > 0) {
            changShangeFilter.sTime = moment(vals.searchChangShangTime[0]).format(dateFormat)
            changShangeFilter.eTime = moment(vals.searchChangShangTime[1]).format(dateFormat)
        } else {
            changShangeFilter.sTime = ''
            changShangeFilter.eTime = ''
        }

        changShangCurrentPage = 1

        getChangShangeList()
    }

    const onChangeTableChangshang = (pagination) => {
        changShangCurrentPage = pagination.current
        changShangPageSize = pagination.pageSize
        getChangShangeList()
    }

    const onChangeTable = (pagination, filters, sorter) => {
        dingdanCurrentPage = pagination.current
        dingdanPageSize = pagination.pageSize
        getAgentClientOrderList()
    }

    const onResetChangShang = () => {
        setFieldsValue({'searchChangShangName': '', 'searchChangShangTime': ''})
    }


    const rowSelection = {
        onSelect: (record, selected) => {
            if (!selected) {
                checkedIdArr.splice(checkedIdArr.indexOf(record.id), 1);
            } else {
                checkedIdArr.push(record.id)
            }
            updateSelected(checkedIdArr)
        },

        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                changeRows.map(item => checkedIdArr.push(item.id))
            } else {
                changeRows.map(item => checkedIdArr.splice(checkedIdArr.indexOf(item.id), 1))
            }
            updateSelected(checkedIdArr)
        },
        selectedRowKeys: checkedIdArr
    }

    const updateSelected = (checkedIdArr) => {
        setCheckedIdArr(checkedIdArr)
        setChecked(checkedIdArr.length)
        selectedIds = checkedIdArr
    }



    const handleMenuClick = ({key}) => {
        if (checked === 0) {
            message.error('请先选择厂商')
            return
        }

        switch (+key) {
            case 1:
                zhuanchu(selectedIds)
                break;
        }
    }

    const menu = (<Menu onClick={handleMenuClick}>
        <Menu.Item key="1">
            转出
        </Menu.Item>

    </Menu>)

    const zhuanchu = (ids) => {
        api.agentFirmTransferCheckRepeat({firmInfoIds:ids,qId:search.qId, clientId:search.id}).then(res => {
            //跳转转出页
            history.push('/agent/manufacturer/apply?ids='+ids+'&qId='+search.qId+'&clientId='+search.id)
        })
    }

    const xufei = (changShang) => {
        api.onCheckVendorRelServicer({
            vendorId: changShang.id
        }).then(() => {
            //跳转续费订单页
            history.push('/orderAgent/renew',{'relationId':detail.id,'vendorId':changShang.id,'qId':search.qId})
        })
    }

    const showChangName = (id) => {
        //跳转续费订单页
        history.push('/csr/customer/customerChange?oaId=' + id)
    }

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        }
      }

    return (

        <Spin spinning={detailLoading}>
            <div className="pro-detail dailishang-client">
                <Descriptions title={
                    (
                        <>
                            <div className='title'>客户名称：
                                <span>{detail.name}（OAID：{detail.id}）</span>
                                [<a onClick={() => showChangName(detail.id)}>名称变更</a>]
                            </div>
                            <Button className="add-customerBtn" type="primary" onClick={() => newOrder()}>新开订单</Button>
                        </>
                    )
                } className="pro-detail-smailtitle lianxiren-title kehu-title"></Descriptions>
                <Descriptions title={
                    (
                        <>
                            <div className='title'>联系人信息</div>
                            <Button className="add-customerBtn" type="primary"
                                    onClick={() => editLianxiren({})}>新增</Button>
                        </>
                    )
                } className="pro-detail-smailtitle lianxiren-title"></Descriptions>

                <Table
                    dataSource={lianxirenList.list}
                    rowKey="id"
                    className="table-page"
                    pagination={{
                        pageSize: lianxirenPageSize,
                        total: lianxirenList.count,
                        current: lianxirenCurrentPage,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
                        showTotal: total => {
                            let totalPages = Math.ceil(total / lianxirenPageSize)
                            return `总共 ${total} 条数据，共 ${totalPages} 页`
                        }
                    }}
                    onChange={onChangeTableLianxiren}
                >
                    <Column title="序号" dataIndex="id" render={(text, record, index) => {
                        return (index + 1)
                    }}/>
                    <Column title="联系人名称" dataIndex="name"/>
                    <Column title="手机号码" dataIndex="mobile"/>
                    <Column title="邮箱" dataIndex="mail"/>
                    <Column title="座机" dataIndex="tel"/>
                    <Column title="QQ" dataIndex="qq"/>
                    <Column title="传真" dataIndex="fax"/>
                    <Column title="创建人" dataIndex="creatorName"/>
                    <Column title="操作" dataIndex={(record) => {
                        // console.log(record)
                    }} render={(text, record) => {
                        return (
                            <a onClick={() => editLianxiren(record)}>编辑</a>
                        )
                    }}/>
                </Table>

                <Tabs 
                    defaultActiveKey={"key1"} 
                    // activeKey={"key1"} 
                    // tabPosition="top" 
                    onChange={onChangeTab}
                    >
                    <TabPane tab="厂商信息" key="key1">
                        <Form onSubmit={onSearchChangshang} {...formItemLayout} className="ant-advanced-search-formTwo">
                            <div className="search-item-wrap">
                                <div className="search-item">
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item label="搜索">
                                                {getFieldDecorator('searchChangShangName', {initialValue: ''})(<Input
                                                    placeholder="项目/产品/厂商ID/厂商账号/厂商客户名称"/>)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item label="创建时间">
                                                {getFieldDecorator('searchChangShangTime', {})(
                                                    <DatePicker.RangePicker/>)}
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <div className="search-btns">
                                                <Button onClick={onResetChangShang}>重置</Button>
                                                <Button style={{marginLeft: 8}} type="primary"
                                                        htmlType="submit">查询</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                        </Form>

                        <div className="add-customer">
                            <Dropdown overlay={menu}>
                              <span className="ant-dropdown-link">
                                <Button>批量操作 <Icon type="ellipsis"/></Button>
                              </span>
                            </Dropdown>
                        </div>
                        <Alert className="count-alert" message={
                            <>
                                <span>已选择 <span style={{color: '#168FFF'}}>{checked}</span> 项</span>
                                <span style={{marginLeft: 20}}>总共{changShangList.count}条数据</span>
                            </>
                        } showIcon/>
                        <Table
                            dataSource={changShangList.list}
                            rowKey="id"
                            className="table-page"
                            scroll={{x: 'max-content'}}
                            loading={loading}
                            pagination={{
                                pageSize: changShangPageSize,
                                total: changShangList.count,
                                current: changShangCurrentPage,
                                showQuickJumper: true,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
                                showTotal: total => {
                                    let totalPages = Math.ceil(total / changShangPageSize)
                                    return `共 ${total} 条记录，第 ${changShangCurrentPage}/${totalPages} 页`
                                }
                            }}
                            onChange={onChangeTableChangshang}
                            rowSelection={rowSelection}
                        >
                            <Column title="序号" dataIndex="number"/>
                            <Column title="厂商ID" dataIndex="vendor"/>
                            <Column title="厂商账号" dataIndex="account"/>
                            <Column title="厂商客户名称" dataIndex="vendorClient"/>
                            <Column title="关联主体" dataIndex="relBranch"/>
                            <Column title="项目" dataIndex="projectName"/>
                            <Column title="产品" dataIndex="productName"/>
                            <Column title="供应商" dataIndex="supplierName"/>
                            <Column title="最后续费订单" dataIndex="lastOrderNo"/>
                            <Column title="创建时间" dataIndex="createTime"/>
                            <Column title="所属渠道开发" dataIndex="relAgentStaffName"/>
                            <Column title="操作" render={
                                (record) => { 
                                    return (
                                    <>
                                        <a onClick={() => zhuanchu([record.id])} style={{marginRight: 5}}>
                                            转出
                                        </a>
                                        <a onClick={() => xufei(record)}>
                                            续费
                                        </a> 
                                    </>
                                    )
                                }
                            }/>
                        </Table>
                    </TabPane>
                    <TabPane tab="订单管理" key="key2">
                        <Alert className="corp-count" message={`总计：${agentClientOrderList.count}条数据`} type="info" showIcon />
                        <Table
                            dataSource={agentClientOrderList.list}
                            rowKey="id"
                            className="table-page"
                            pagination={{
                            pageSize: dingdanPageSize,
                            total: agentClientOrderList.count,
                            current: dingdanCurrentPage,
                            showQuickJumper: true,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
                            }}
                            scroll={{x: 'max-content'}}
                            onChange={onChangeTable}
                        >
                            <Column title="订单编号" dataIndex="number" />
                            <Column title="客户名称" dataIndex="client" />
                            <Column title="项目" dataIndex="projectName" />
                            <Column title="产品" dataIndex="productName" />
                            <Column title="业务类型" dataIndex="businessName" />
                            <Column title="厂商ID" dataIndex="vendor" />
                            <Column title="收款类型" dataIndex="colTypeName" />
                            <Column title="市场价" dataIndex="colmarketprice" />
                            <Column title="实收" dataIndex="colactualprice" />
                            <Column title="订单状态" dataIndex="auditStatusName" />
                            <Column title="代理商" dataIndex="agent" />
                        </Table>
                    </TabPane>
                </Tabs>
            </div>
            <ClientLianxiren setVisible={setLianxirenShow} id={search.id} refrashList={getLianxirenList}
                             visible={lianxirenShow} lianxiren={lianxiren}/>
        </Spin>
    )
}

export default Form.create()(Client);