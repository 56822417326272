/**
 * 模块名称: 代理商管理-资金列表
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from 'react'
import api from '@/api'
import {
    Form,
    Input,
    Modal, message, Row, Col, Icon, Select, Button, DatePicker, Table, Tabs
} from 'antd'
import moment from 'moment'
import {getLastNDate} from "../../../utils";

const Column = Table.Column
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1
let params = {}
const dateFormat = 'YYYY-MM-DD'
let currentType = 0;


let filter = {
    corpId: ''
}

const Zijin = (props) => {
    const [filters, setFilters] = useState({})

    const [expand, setExpand] = useState(false)
    const [statusVal, setStatusVal] = useState(undefined)
    const [filterContent, setFilterContent] = useState([])
    const [filterInfoValue, setFilterInfoValue] = useState('')
    const [visible, setVisible] = useState(false)

    const [list, setList] = useState({})
    const [loading, setLoading] = useState(false)
		const [statusValName, setStatusValName] = useState(undefined)
  	const [flag, setFlag] = useState(false)

    const {location,id} = props
    const {getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue} = props.form

    useEffect(() => {
        const apis = []
        //资金明细的筛选条件
        apis.push(api.agentGetFundFilterData({qId:id}));

        Promise.all(apis).then(
            res => {
                setFilters(res.shift())
            }
        )
        getList()
    }, [])

    const getList = () => {
        const vals = getFieldsValue([
            'searchTime',
            'businessType',
            'accountId',
            'cName',
        ]);
        if(vals.searchTime && vals.searchTime.length > 0){
            filter.sTime = moment(vals.searchTime[0]).format(dateFormat)
            filter.eTime = moment(vals.searchTime[1]).format(dateFormat)
        }else{
            filter.sTime = ''
            filter.eTime = ''
        }
        delete vals.searchTime
        setLoading(true)
        api.agentGetFundDetailList({...filter, ...vals, rangeType: currentType, qId:id, limit: pageSize, page: currentPage}).then(
            res => {
                setList(res)
            }
        ).finally(() => {
            setLoading(false)
        })
    }


    // 查询
    const onSearch = (e) => {
        e.preventDefault()
        validateFields((err, vals) => {
            if (!err) {
                if (vals.searchTime) {
                    delete vals.searchTime
                }
                filter = {...filter, ...vals}
                currentPage = 1
                getList()
            }
        })
    }

    const onToggle = () => {
        setExpand(!expand)
    }

    const onChangeGetFilter = (val) => {
			val ? setFlag(true) : setFlag(false)
      // 下拉框搜索内容
      val && api.getSearchStorage({key: location.pathname}).then(data => {
        setFilterContent(data.list)
      })
    }

    const onChecked = (value, val2) => {
        setStatusVal(value)
				setStatusValName(val2.props.children.props.children[0])
        // let data = JSON.parse(value)
        let data = filterContent[value].content
        let fields = getFieldsValue()
        let params = {};
        delete fields['searchTime']

        for (let i in fields) {
            params[i] = data[i] ? data[i] : undefined
        }
        params['searchTime'] = data.sTime ? [moment(data.sTime, dateFormat), moment(data.eTime, dateFormat)] : undefined

        setFieldsValue(params)
    }

    // 筛选删除
    const onSearchDelete = (id) => {
        api.searchStorageDelete({
        id: id
        }).then(() => {
        api.getSearchStorage({ key: location.pathname }).then(data => {
            setFilterContent(data.list)
        })
        })
    }

    const onSaveFilterTerm = () => {
        validateFields((err, vals) => {
            if (!err) {
                const values = Object.values(vals)
                if (values.some(item => item !== undefined)) {
                    params = {}
                    if(vals.searchTime && vals.searchTime.length){
                        params.sTime = moment(vals.searchTime[0]).format(dateFormat)
                        params.eTime = moment(vals.searchTime[1]).format(dateFormat)
                        delete vals.searchTime
                    }

                    for (let k in vals) {//eslint-disable-line
                        if (vals[k] !== undefined) {
                            params[k] = vals[k]
                        }
                    }

                    setVisible(true)
                } else {
                    message.error('当前没有设置筛选条件')
                }
            }
        })
    }

    const onReset = () => {
        resetFields()
				setStatusVal(undefined)
				setStatusValName(undefined)
        filter.sTime = ''
        filter.eTime = ''
        currentPage = 1
        getList()
    }

    // 弹出框确定
    const HandleOk = () => {
        api.saveSearchStorage({
            name: filterInfoValue,
            key: location.pathname,
            content: params
        }).then(data => {
            setVisible(false)
            message.success('添加成功')
        })
    }

    const HandleCancel = () => {
        setVisible(false) //弹出框内取消
    }

    const FilterInfoChange = (e) => {
        setFilterInfoValue(e.target.value) //弹框input值
    }

    const jinri = () => {
        const date = getLastNDate(0)
        setFieldsValue({
            searchTime: [moment(date, dateFormat), moment(date, dateFormat)]
        })
    }
    const zuori = () => {
        const date = getLastNDate(1)
        setFieldsValue({
            searchTime: [moment(date, dateFormat), moment(date, dateFormat)]
        })
    }

    const last7 = () => {
        setFieldsValue({
            searchTime: [moment(getLastNDate(6), dateFormat), moment(getLastNDate(0), dateFormat)]
        })
    }

    const last30 = () => {
        setFieldsValue({
            searchTime: [moment(getLastNDate(29), dateFormat), moment(getLastNDate(0), dateFormat)]
        })
    }

    const onChangeTable = (pagination) => {
        currentPage = pagination.current
        pageSize = pagination.pageSize
        getList()
    }
    const onChangeTab = (key) => {
        currentType = key - 1;
        currentPage = 1;
        getList()
    }

    const MyTable = () => {

        return (
            <Table
                dataSource={list.list}
                rowKey="id"
                className="table-page"
                loading={loading}
                pagination={{
                    pageSize,
                    total: list.count,
                    current: currentPage,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '50', '100', '500', '1000', '2000'],
                    showTotal: total => {
                        let totalPages = Math.ceil(total/pageSize)
                        return `共 ${total} 条记录，第 ${currentPage}/${totalPages} 页`
                    }
                }}
                onChange={onChangeTable}
            >
                <Column title="入账时间" dataIndex="finishedTime"/>
                <Column title="业务类型" dataIndex="businessType"/>
                <Column title="业务编号" dataIndex="businessNo"/>
                <Column title="客户名称" dataIndex="relationName"/>
                <Column title="主体" dataIndex="companyName"/>
                <Column title="项目/产品/业务" key={Math.random()} render={(record) => {
                    return `${record.projectName!='' ? record.projectName+'/' : ''}${record.productName!='' ? record.productName+'/' : ''}${record.projectBusinessName!='' ? record.projectBusinessName+'/' : ''}`
                }}/>
                <Column title="收支金额（元）" key={Math.random()} render={(record) => {
                    return (<span className={record.cashType === 1 ? 'cashtype-green' : 'cashtype-red'}>{record.cashType === 1 ? '+' + record.income : '-' + record.cost}</span>)

                }}/>
                <Column title="主体余额" dataIndex="balance"/>
                <Column title="操作人" dataIndex="operateStaffName" />
            </Table>
        )
    }

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        }
    }

    return (
        <div  className='agent-ziji'>
            <Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
                <div className="search-item-wrap">
                    <div className="search-item">
                        <Row gutter={24}>
                            <Col span={9}>
                                <Form.Item label="入账时间">
                                    {getFieldDecorator('searchTime', {
                                    })(<DatePicker.RangePicker/>)}
                                </Form.Item>
                            </Col>
                            <Col span={13} className="search-buttons">
                                <Button onClick={jinri}>今日</Button>
                                <Button onClick={zuori}>昨日</Button>
                                <Button onClick={last7}>近7日</Button>
                                <Button onClick={last30}>近30日</Button>
                            </Col>
                        </Row>
                        <Row gutter={24} className={expand ? '' : 'hide'}>
                            <Col span={8}>
                                <FormItem label="业务类型" labelCol={{span: 8}}>
                                    {getFieldDecorator('businessType')(<Select
                                        placeholder="请选择"
                                    >
                                        {
                                            filters.businessType !== undefined
                                                &&
                                            filters.businessType.map((item) => <Select.Option value={item.id} key={item.id}>{item.name}</Select.Option>)
                                        }
                                    </Select>)}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="主体" labelCol={{span: 8}}>
                                    {getFieldDecorator('accountId')(<Select
                                        placeholder="请选择"
                                    >
                                        {
                                            filters.accountData !== undefined
                                            &&
                                            filters.accountData.map((item) => <Select.Option value={item.companyId} key={item.companyId}>{item.companyName}</Select.Option>)
                                        }
                                    </Select>)}
                                </FormItem>
                            </Col>
                            <Col span={8}>
                                <FormItem label="客户名称" labelCol={{span: 8}}>
                                    {getFieldDecorator('cName')(<Input placeholder="请输入"/>)}
                                </FormItem>
                            </Col>
                        </Row>
                    </div>
                    <span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle}
                          style={{cursor: 'pointer', fontSize: '12px', color: '#1890ff'}}>
                        <span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'}/></span>
                      </span>
                </div>
                <div className="search-btns">

                    <div className={`search-btns ${expand ? '' : 'hide'}`}>
                        <h4 className="filter-title">已保存筛选方案：</h4>
                        <div className="filter-select">
                            <Select
                                style={{display: 'inline-block', width: '160px'}}
                                placeholder="请选择"
                                value={statusVal}
                                onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
                                onSelect={onChecked}
                            >
                                {filterContent.map((item, index) =>
                                    <Option value={index} key={index}>
                                        <div className="closeStyBtn">
                                        {item.name}
                                        {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                                            e.stopPropagation()
                                            onSearchDelete(item.id)
                                        }} /></span> : null}
                                        </div>
                                    </Option>
                                )}
                            </Select>
                        </div>
                        <Button className="filter-savebtn" onClick={onSaveFilterTerm}
                                style={{width: 130, margin: '0 15px'}}>保存筛选条件</Button>
                    </div>

                    <Button onClick={onReset}>重置</Button>
                    <Button style={{marginLeft: 8}} type="primary" htmlType="submit">查询</Button>
                    <Modal
                        title="保存筛选条件"
                        visible={visible}
                        onOk={HandleOk}
                        onCancel={HandleCancel}
                    >
                        <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange}/>
                    </Modal>
                </div>
            </Form>
            <div>
                <Row className="zijin-banner">
                    <Col span={12} className="zijin-banner-shouru">
                        <div className="zijin-banner-title">收入</div>
                        <div className="zijin-banner-money">{list.statistics !== undefined && list.statistics.incomeInfo.totalMoney > 0 ? '+'+list.statistics.incomeInfo.totalMoney+'元' : 0}</div>
                        <div className="zijin-banner-desc">{list.statistics !== undefined && list.statistics.incomeInfo.totalCount}笔</div>
                    </Col>
                    <Col span={12} className="zijin-banner-zhichu">
                        <div className="zijin-banner-title">支出</div>
                        <div className="zijin-banner-money">{list.statistics !== undefined && list.statistics.costInfo.totalMoney > 0 ? '-'+list.statistics.costInfo.totalMoney+'元' : 0}</div>
                        <div className="zijin-banner-desc">{list.statistics !== undefined && list.statistics.costInfo.totalCount}笔</div>
                    </Col>
                </Row>
            </div>

            <Tabs defaultActiveKey="1" onChange={onChangeTab}>
                <Tabs.TabPane tab="全部" key="1">
                    <MyTable />
                </Tabs.TabPane>
                <Tabs.TabPane tab="收入" key="2">
                    <MyTable />
                </Tabs.TabPane>
                <Tabs.TabPane tab="支出" key="3">
                    <MyTable />
                </Tabs.TabPane>
            </Tabs>


        </div>
    )
}

export default Form.create()(Zijin)