/**
 * 模块名称: 代理商详情-信用额度调整
 * @author wangchanghong@372163.com
 */

import React, {useEffect} from 'react'
import {
    Form,
    Modal, message, Select,Radio
} from 'antd'

const Option = Select.Option
const FormItem = Form.Item

const XinYongEDuTiaoZheng = (props) => {

    const form = props.form;
    const { getFieldDecorator, validateFields, setFieldsValue } = form
    const { id, zhutiList, history, visible, setVisible} = props


    useEffect(() => {
        setFieldsValue({accountType:0})
    }, [zhutiList])

    const onOk = () => {
        validateFields((err, vals) => {
            if (!err) {
                const account = JSON.parse(vals.account)
                // console.log(vals.accountType, account.accountId)
                history.push(`/account/credit?id=${+id}&type=${1}&accountType=${vals.accountType}&accountId=${account.accountId}`)
                // const account = JSON.parse(vals.account);
                // history.push('/account/creditapply/edit?customerType=1&customerId='+id+
                //     '&accountType='+vals.accountType+
                //     '&company='+account.accountName+
                //     '&trueAmount='+(vals.accountType === 0 ? account.changqi : account.linshi)+
                //     '&usedAmount='+(vals.accountType === 0 ? account.changqiUsed : account.linshiUsed)+
                //     '&accountId='+account.creditId+
                //     '&companyId='+account.accountId)
            } else {
                for (let i in err) {
                    message.error(err[i].errors[0].message)
                }
            }
        })
    }

    const onCancel = () => {
        setVisible(false)
    }

    return (
        <Modal
            title="信用额度调整"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            width={600}
            maskClosable={true}
            okText={"继续"}
        >
            <FormItem label="主体" >
                {getFieldDecorator('account',{
                    rules: [{required: true, message: '请选择主体'}]
                })(
                    <Select
                        placeholder="请选择"
                        style={{minWidth:'240px'}}
                    >
                        {zhutiList.map(item=><Option key={JSON.stringify(item)}>{item.accountName}</Option>)}
                    </Select>
                )}
            </FormItem>
            <FormItem>
                {getFieldDecorator('accountType')(
                    <Radio.Group>
                        <Radio value={0}>长期</Radio>
                        <Radio value={1}>临时</Radio>
                    </Radio.Group>
                )}
            </FormItem>
        </Modal>
    )
}

export default Form.create()(XinYongEDuTiaoZheng)