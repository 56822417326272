/**
 * 模块名称: 代理商列表
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
	Table,
	Form,
	Alert,
	Button,
	Input,
	Select,
	message,
	Icon,
	Row,
	Col,
	Modal, 
  Drawer, 
  Dropdown, 
  Menu,
  Tooltip
} from 'antd'

import { parseSearch } from '@/utils'
import CorpFilter from '@/components/CorpFilter'
import { Link } from "react-router-dom"
import Fenpei from "./components/fenpei"
import PaybackList from './components/PaybackList'
import CryptoJS from 'crypto-js'

const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
let pageSize = 10
let currentPage = 1

let filter = {
	corpId: '',
	qId: '',
	aName: '',
	cName: '',
	oaId: '',
	firmId: '',
	contract: '',
}

let params = {}
let listObjes = {}


const Agent = (props) => {
	const { match, location, history } = props
	const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldsValue } = props.form

	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [count, setCount] = useState(0)
	const [expand, setExpand] = useState(false)
	const search = parseSearch(location.search)
	const [statusVal, setStatusVal] = useState(undefined)
	const [filterContent, setFilterContent] = useState([])
	const [filterInfoValue, setFilterInfoValue] = useState('')
	const [visible, setVisible] = useState(false)
	const [checked, setChecked] = useState(0)
	const [checkedIdArr, setCheckedIdArr] = useState([])
	const [hetongVsible, setHetongVsible] = useState(false)
	const [hetongList, setHetongList] = useState([])
	const [fenPeiVisible, setFenPeiVisible] = useState(false)
	const [persons, setPersons] = useState([])
	const [oUsers, setOUsers] = useState([])
	const [authList, setAuthList] = useState([])
	const [drawerName, setDrawerName] = useState('')
	const [paybackVisible, setPaybackVisible] = useState(false)
	const [statusValName, setStatusValName] = useState(undefined)
	const [flag, setFlag] = useState(false)

	useEffect(() => {
		// console.log(history.location.state)
		filter.corpId = ''
		if (!history.location.state) {
			currentPage = search.page ? +search.page : 1
			pageSize = search.pageSize ? +search.pageSize : 10
			filter = {
				qId: '',
				aName: '',
				cName: '',
				oaId: '',
				firmId: '',
				contract: '',
			}
		} else {
			currentPage = search.page ? +search.page : currentPage
			pageSize = search.pageSize ? +search.pageSize : pageSize
			// console.log(filter)
			setTimeout(() => {
				setFieldsValue({
					qId: filter.qId !== '' ? filter.qId : undefined,
					aName: filter.aName !== '' ? filter.aName : undefined,
					cName: filter.cName !== '' ? filter.cName : undefined,
					oaId: filter.oaId !== '' ? filter.oaId : undefined,
					firmId: filter.firmId !== '' ? filter.firmId : undefined,
					contract: filter.contract !== '' ? filter.contract : undefined
				})
			}, 1000)
		}
		getList()
		getPageAuth()
	}, [])

	const getList = () => {
		setLoading(true)
		api.agentGetList({ ...filter, limit: pageSize, page: currentPage })
			.then(data => {
				setLoading(false)
				data.list.map(item => listObjes[item.id] = item)
				setList(data.list)
				setCount(data.count)
			})
			.catch(() => setLoading(false))
	}

	// 查询
	const onSearch = (e) => {
		e.preventDefault()
		validateFields((err, vals) => {
			if (!err) {
				if (vals.date) {
					delete vals.date
				}
				currentPage = 1
				history.replace(match.path)
				filter = { ...filter, ...vals }
				setChecked(0)
				setCheckedIdArr([])
				getList()
			}
		})
	}

	const onChangeTable = (pagination, filters, sorter) => {
		currentPage = pagination.current
		pageSize = pagination.pageSize
		history.replace(`${match.path}?page=${currentPage}&pageSize=${pageSize}`)
		getList()
	}

	const onChangeCorp = (data) => {
		setChecked(0)
		setCheckedIdArr([])
		filter.corpId = data.map(item => item.id).join(',')
		history.replace(match.path)
		currentPage = 1
		getList()
	}

	const onToggle = () => {
		setExpand(!expand)
	}

	const onReset = () => {
		resetFields()
		filter = {
			corpId: filter.corpId,
			qId: '',
			aName: '',
			cName: '',
			oaId: '',
			firmId: '',
			contract: '',
		}
		setStatusVal(undefined)
		setStatusValName(undefined)
		currentPage = 1
		history.replace(match.path)
		setChecked(0)
		setCheckedIdArr([])
		getList()
	}

	// 弹出框确定
	const HandleOk = () => {
		api.saveSearchStorage({
			name: filterInfoValue,
			key: location.pathname,
			content: params
		}).then(data => {
			setVisible(false)
			message.success('添加成功')
		})
	}

	const HandleCancel = () => {
		setVisible(false) //弹出框内取消
	}

	const FilterInfoChange = (e) => {
		setFilterInfoValue(e.target.value) //弹框input值
	}

	const onChangeGetFilter = (val) => {
		val ? setFlag(true) : setFlag(false)
		// 下拉框搜索内容
		val && api.getSearchStorage({ key: location.pathname }).then(data => {
			setFilterContent(data.list)
		})
	}

	const onChecked = (value, val2) => {
		setStatusVal(value)
		setStatusValName(val2.props.children.props.children[0])
		// let data = JSON.parse(value)
		let data = filterContent[value].content
		let fields = getFieldsValue()
		let params = {};
		delete fields['date']

		for (let i in fields) {
			params[i] = data[i] ? data[i] : undefined
		}
		setFieldsValue(params)
	}

	// 筛选删除
	const onSearchDelete = (id) => {
		api.searchStorageDelete({
			id: id
		}).then(() => {
			api.getSearchStorage({ key: location.pathname }).then(data => {
				setFilterContent(data.list)
			})
		})
	}

	const onSaveFilterTerm = () => {
		validateFields((err, vals) => {
			if (!err) {
				const values = Object.values(vals)
				if (values.some(item => item !== undefined)) {
					params = {}

					for (let k in vals) {//eslint-disable-line
						if (vals[k] !== undefined) {
							params[k] = vals[k]
						}
					}

					setVisible(true)
				} else {
					message.error('当前没有设置筛选条件')
				}
			}
		})
	}

	const rowSelection = {
		onSelect: (record, selected) => {
			if (!selected) {
				checkedIdArr.splice(checkedIdArr.indexOf(record.id), 1);
			} else {
				checkedIdArr.push(record.id)
			}
			updateSelected(checkedIdArr)
		},

		onSelectAll: (selected, selectedRows, changeRows) => {
			// console.log(selected, selectedRows, changeRows)
			if (selected) {
				changeRows.map(item => checkedIdArr.push(item.id))
			} else {
				changeRows.map(item => checkedIdArr.splice(checkedIdArr.indexOf(item.id), 1))
			}
			updateSelected(checkedIdArr)
		},
		selectedRowKeys: checkedIdArr
	}

	const updateSelected = (checkedIdArr) => {
		setCheckedIdArr(checkedIdArr)
		setChecked(checkedIdArr.length)
	}

	const showHetong = (contract) => {
		api.agentGetContractList({ contract })
			.then((res) => {
				setHetongVsible(true)
				setHetongList(res.list)
			})
	}

	const handleMenuClick = ({ key }) => {
		if (checked === 0) {
			message.error('请先选择代理商')
			return
		}

		switch (+key) {
			case 1:
				showFenPei()

				break;
			case 2:
				api.getAgentVerifyAgentPrincipal({ qId: checkedIdArr.join(',') })
					.then(() => {
						history.push('/agent/agentTransfer/add?qId=' + checkedIdArr.join(','))
					})
				break;
		}
	}

	const getPageAuth = () => {
		api.getPageAuth().then(list => {
			setAuthList(list)
		})
	}

	const showFenPei = () => {
		if (checkedIdArr.length > 1) {
			setOUsers([])
		} else {
			setOUsers(listObjes[checkedIdArr[0]].principal === null ? [] : [...listObjes[checkedIdArr[0]].principal])
		}

		if (persons.length === 0) {
			setLoading(true)
			api.agentGetDepartmentStaff().then(
				(res) => {
					setLoading(false)
					setPersons(res)
					setFenPeiVisible(true)
				}
			)
		} else {
			setFenPeiVisible(true)
		}
	}

	const menu = (<Menu onClick={handleMenuClick}>
		{
			authList.includes('distribution-manager') &&
			<Menu.Item key="1">
				分配负责人
      </Menu.Item>
		}
		{
			authList.includes('transmit-to') &&
			<Menu.Item key="2">
				转出
      </Menu.Item>
		}

	</Menu>)

	// 负利
	const onPayback = () => {
		setPaybackVisible(true)
		setDrawerName('收款申请')
	}

	const formItemLayout = {
		labelCol: {
			xs: { span: 24 },
			sm: { span: 8 },
		},
		wrapperCol: {
			xs: { span: 24 },
			sm: { span: 16 },
		}
	}

	return (
		<>
			<CorpFilter onChange={onChangeCorp} />
			<Form onSubmit={onSearch} {...formItemLayout} className="ant-advanced-search-formTwo">
				<div className="search-item-wrap">
					<div className="search-item">
						<Row gutter={24}>
							<Col span={8}>
								<FormItem label="QID" labelCol={{ span: 8 }}>
									{getFieldDecorator('qId', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label="代理商名称" labelCol={{ span: 8 }}>
									{getFieldDecorator('aName', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label="客户名称" labelCol={{ span: 8 }}>
									{getFieldDecorator('cName', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={24} className={expand ? '' : 'hide'}>
							<Col span={8}>
								<FormItem label="OAID" labelCol={{ span: 8 }}>
									{getFieldDecorator('oaId', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label="厂商ID" labelCol={{ span: 8 }}>
									{getFieldDecorator('firmId', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
							<Col span={8}>
								<FormItem label="合同号" labelCol={{ span: 8 }}>
									{getFieldDecorator('contract', { initialValue: '' })(<Input placeholder="请输入" />)}
								</FormItem>
							</Col>
						</Row>
					</div>
					<span className={`expand-btn${expand ? ' show-all' : ''}`} onClick={onToggle} style={{ cursor: 'pointer', fontSize: '12px', color: '#1890ff' }}>
						<span>{expand ? '收起' : '展开'} <Icon type={expand ? 'up' : 'down'} /></span>
					</span>
				</div>
				<div className="search-btns" style={{paddingBottom: 10}}>

					<div className={`search-btns ${expand ? '' : 'hide'}`}>
						<h4 className="filter-title">已保存筛选方案：</h4>
						<div className="filter-select">
							<Select
								style={{ display: 'inline-block', width: '160px' }}
								placeholder="请选择"
								value={statusVal}
								onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
								onSelect={onChecked}
							>
								{filterContent.map((item, index) =>
									<Option value={index} key={index}>
										<div className="closeStyBtn">
											{item.name}
											{flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
												e.stopPropagation()
												onSearchDelete(item.id)
											}} /></span> : null}
										</div>
									</Option>
								)}
							</Select>
						</div>
						<Button className="filter-savebtn" onClick={onSaveFilterTerm} style={{ width: 130, margin: '0 15px' }}>保存筛选条件</Button>
					</div>

					<Button onClick={onReset}>重置</Button>
					<Button style={{ marginLeft: 8 }} type="primary" htmlType="submit">查询</Button>
					<Modal
						title="保存筛选条件"
						visible={visible}
						onOk={HandleOk}
						onCancel={HandleCancel}
					>
						<Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
					</Modal>
				</div>
			</Form>
			<div className="line"></div>
			<div className="add-customer">
				{
					authList.includes('negative-interest-repayment') &&
					<Button className="add-customerBtn" style={{ marginRight: 15 }} type="primary" onClick={onPayback}>负利收款</Button>
				}
				{
					authList.includes('newly-add') &&
					<Link to='/agent/manage/agentNewAdd' style={{ marginRight: 15 }}>
						<Button className="add-customerBtn" type="primary" icon="plus">新增</Button>
					</Link>
				}

				{
					(authList.includes('distribution-manager') || authList.includes('transmit-to')) &&
					<Dropdown overlay={menu}>
						<Button>
							更多操作 <Icon type="down" />
						</Button>
					</Dropdown>
				}

			</div>
			<Alert className="corp-count" message={
				<>
					<span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
					<span style={{ marginLeft: 20 }}>总共{count}条数据</span>
				</>
			} type="info" showIcon />
			<Drawer
				title={drawerName}
				width={800}
				onClose={() => setPaybackVisible(false)}
				visible={paybackVisible}
				destroyOnClose={true}
			>
				<PaybackList drawerName={drawerName} history={history}></PaybackList>
			</Drawer>
			<Table
				size="small"
				dataSource={list}
				rowKey="id"
				className="table-page"
				loading={loading}
				pagination={{
					pageSize,
					total: count,
					current: currentPage,
					showQuickJumper: true,
					showSizeChanger: true,
					pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
				}}
				onChange={onChangeTable}
				rowSelection={rowSelection}
				scroll={{x: 'max-content'}}
			>
				<Column title="QID" dataIndex="id" />
				<Column title="代理商名称" render={(record) => {
					return (<Link className="operate-icon" to={`${match.path}/detail?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>{record.name}</Link>)
				}} />
				<Column title="客户数" dataIndex="clientNum" />
				<Column title="可用余额" dataIndex="remainMoney" />
				<Column title="信用余额（长期/临时）" render={(record) => {
					return record.creditMoney === null ? null : `（${record.creditMoney.changqi}/${record.creditMoney.linshi}）`
				}} />
				<Column title="可用积分" dataIndex="integral" />
				<Column title="合同" render={(record) => {
					return (<a className="operate-icon" onClick={() => showHetong(record.contract)}>{"查看"}</a>)
				}} />
				<Column title="创建人" dataIndex="creatorName" />
				<Column title="负责人" render={(record) => {
					if (record.principal && record.principal.length !== 0) {
					   const fuzeren = record.principal.map((item) => item.principalName)
					   return fuzeren.join('，')
					}
				}} />
				<Column title="操作" key="set" fixed="right" render={(text, record) => (
					<Tooltip title="在新页面打开详情页" placement="bottom">
            			<Link target='_blank' className="operate-icon" to={`${match.path}/detail?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`}>
							<Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginLeft: '8px' }} />
						</Link>
					</Tooltip>
				)} />
			</Table>

			<Drawer
				title="合同明细记录表"
				width={1000}
				onClose={() => setHetongVsible(false)}
				visible={hetongVsible}
			>
				<Table
					dataSource={hetongList}
					rowKey="id"
					className="table-page"
					scroll={{ x: 900 }}
				>
					<Column title="序号" dataIndex="number" />
					<Column title="合同号" dataIndex="contractNo" />
					<Column title="关联主体" dataIndex="accountName" />
					<Column title="项目" dataIndex="projectNames" />
					<Column title="产品" dataIndex="productNames" />
					<Column title="合同领取人" dataIndex="receiver" />
					<Column title="负责人" dataIndex="leadCadreName" />
					<Column title="合同期限" render={(record) => {
						return record.beginTime + ' ~ ' + record.endTime
					}} />
				</Table>
			</Drawer>
			<Fenpei persons={persons} refrashList={getList} qIds={checkedIdArr} oUsers={oUsers} visible={fenPeiVisible}
				setVisible={setFenPeiVisible} />

		</>
	)
}

export default Form.create()(Agent)