/**
 * 模块名称: 代理商详情-联系人信息
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from 'react'
import api from '@/api'
import {
    Form,
    Modal, message, Select
} from 'antd'

const Option = Select.Option
const FormItem = Form.Item
let personsIdList = []

const Fenpei = (props) => {

    const form = props.form;
    const { getFieldDecorator, validateFields, resetFields, setFieldsValue } = form
    const { qIds, oUsers, persons, refrashList, visible, setVisible} = props

    const [submitLoading, setSubmitLoading] = useState(false)
    const [personsOption, setPersonsOption] = useState([])

    useEffect(() => {
        personsIdList = []
        const personsOption = persons.map((item) => {
            personsIdList.push(item.id)
            return <Option key={JSON.stringify(item)}>{item.staffName}</Option>
        })

        setPersonsOption(personsOption)
        const vals = []
        for(let item of oUsers){
            if(personsIdList.includes(item.principalId)){
                vals.push(JSON.stringify({id:item.principalId, staffName:item.principalName}))
            }
        }

        setFieldsValue({
            persons:vals
        })
    }, [persons, oUsers])

    const onOk = () => {
        validateFields((err, vals) => {
            if (!err) {
                let submitVals = {
                    principal: [],
                    delPrincipal: [],
                    qId:qIds
                };
                const subUsers = []
                vals.persons.map(item => {
                    let p = JSON.parse(item)

                    submitVals.principal.push({id:p.id, name:p.staffName})
                    subUsers.push(p.id)
                })


                if(qIds.length === 1){//如果只用一个代理商，则计算删除的负责人
                    oUsers.map(item => {
                        if(!subUsers.includes(item.principalId)){
                            submitVals.delPrincipal.push(item.principalId)
                        }
                    })
                }

                setSubmitLoading(true)
                api.agentPrincipalAllotPrincipal(submitVals).then(
                    res => {
                        refrashList()
                        setVisible(false)
                    }
                ).finally(() => setSubmitLoading(false))

            } else {
                for (let i in err) {
                    message.error(err[i].errors[0].message)
                }
            }


        })
    }

    const onCancel = () => {
        setVisible(false)
    }

    return (
        <Modal
            title="分配负责人"
            visible={visible}
            onOk={onOk}
            confirmLoading={submitLoading}
            onCancel={onCancel}
            width={600}
            maskClosable={true}
        >
            <FormItem label="代理商负责人" >
                {getFieldDecorator('persons',{
                    rules: [{required: true, message: '请选择负责人'}]
                })(
                    <Select
                        showSearch
                        mode="multiple"
                        placeholder="请选择"
                        optionFilterProp="children"
                        style={{minWidth:'240px'}}
                        filterOption={(input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                    >
                        {personsOption}
                    </Select>
                )}
            </FormItem>
        </Modal>
    )
}

export default Form.create()(Fenpei)