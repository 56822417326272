/**
 * 模块名称: 代理商管理
 * @author wangchanghong@372163.com
 */

import React from 'react'
import {Switch, Route} from 'react-router-dom'
import Main from './Main'
import Details from './Details'
import Add from '../AgentNew/Details'
import Client from './Client'

const Agent = ({match, history}) => {

    return (
        <div>
            <Switch>
                <Route path={match.path + '/detail'} component={Details}/>
                <Route path={match.path + '/client'} component={Client}/>
                <Route path={match.path + '/agentNewAdd'} component={Add} />
                <Route component={Main}/>
            </Switch>
        </div>
    )
}

export default Agent