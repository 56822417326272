/**
 * 模块名称: 代理商详情-续签
 * @author wangchanghong@372163.com
 */

import React, {useState} from 'react'
import api from '@/api'
import {
    Form, Modal, message, Select, Spin
} from 'antd'

const FormItem = Form.Item

const XuQian = (props) => {

    const form = props.form;
    const { getFieldDecorator, validateFields } = form
    const { id, visible, setVisible} = props

    const [submitLoading, setSubmitLoading] = useState(false)
    const [contractsList, setContractsList] = useState([])
    const [fetching, setFetching] = useState(false)


    const onOk = () => {
        validateFields((err, vals) => {
            if (!err) {
                let submitVals = {
                    qId: id,
                    newContract: vals.contracts,
                    id:0
                };

                setSubmitLoading(true)
                api.agentChangeHandleContract(submitVals).then(
                    res => {
                        message.success('续签申请已经提交')
                        setVisible(false)
                    }
                ).finally(() => setSubmitLoading(false))

            } else {
                for (let i in err) {
                    message.error(err[i].errors[0].message)
                }
            }


        })
    }

    const onCancel = () => {
        setVisible(false)
    }


    const onSearchContract = (value) => {
        if(!value){
            setContractsList([])
        }

        if(value && value.trim() !== '') {
            setFetching(true)
            api.agentGetRenewContractList({qId: id, likeContractNo: value})
                .then(res => {
                    setFetching(false)
                    setContractsList(res)
                })
        }
    }

    return (
        <Modal
            title={
                (
                    <>
                        续签<span>请输入正确的合同号，合同乙方需要与续签代理商名称完全一致。</span>
                    </>
                )
            }
            visible={visible}
            onOk={onOk}
            confirmLoading={submitLoading}
            onCancel={onCancel}
            width={600}
            maskClosable={true}
        >
            <FormItem label="合同号" >
                {getFieldDecorator('contracts',{
                    rules: [{required: true, message: '请输入合同号'}]
                })(
                    <Select
                        mode="multiple"
                        placeholder="请选择"
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        filterOption={false}
                        style={{minWidth:'340px'}}
                        onSearch={onSearchContract}
                        filterOption={(input, option) => {
                            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                    >
                        {contractsList.map(item => (
                            <Select.Option key={item.contractNo}>{item.contractNo}</Select.Option>
                        ))}
                    </Select>
                )}
            </FormItem>
        </Modal>
    )
}

export default Form.create()(XuQian)