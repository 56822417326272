/*
 * @Author: zhuyan
 * 2022新业务关联
 */
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import NewBiz from './NewBiz'

const NewBizIndex = ({ match, history }) => {

    return (
        <Switch>
            <Route component={NewBiz} />
        </Switch>
    )
}

export default NewBizIndex