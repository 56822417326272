/**
 * 模块名称: 代理商详情页
 * @author wangchanghong@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Form,
  Tabs,
  Button,
  message,
  Spin
} from 'antd'


import './assets/style.scss'
import DetailPage from "./components/detailpage"
import Zijin from "./components/zijin"
import Zhekou from "./components/zhekou"
import {parseSearch} from "../../utils"
import CryptoJS from 'crypto-js'

const { TabPane } = Tabs;

const Detail = (props) => {

  const {location, history} = props
  const search = parseSearch(location.search)
  const [detailLoading, setDetailLoading] = useState(true)
  // console.log(search.new)

  useEffect(() => {
    let plaintext = search.sign
    let plaintextTwo = CryptoJS.MD5(search.id + 'ad629fddf8b8756d2e72e96ae035a5a4' + search.time).toString()

    if (plaintext == plaintextTwo && +new Date() - +search.time <= 21600000) {
      setDetailLoading(false)
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  //退回列表页
  const onGoBack = () => {
    history.push({pathname:'/agent/manage',state: { goBack: 1}})
  }

  return (
      <Spin spinning={detailLoading}>
        {
          search.new && search.new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: '20px',
            top: '-20px'
          }}>返回上一页</Button>
        }
        <Tabs defaultActiveKey="1">
          <TabPane tab="代理商详情" key="1">
            <DetailPage id={search.id} history={history}/>
          </TabPane>
          <TabPane tab="资金明细" key="2">
              <Zijin id={search.id} location={location}/>
          </TabPane>
          <TabPane tab="折扣列表" key="3">
              <Zhekou id={search.id} location={location}/>
          </TabPane>
        </Tabs>
      </Spin>
  )
}

export default Form.create()(Detail);