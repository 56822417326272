/**
 * 模块名称: 数据分析中心
 * @author zhuyan@372163.com
 */

import React from 'react'
import { Switch, Route } from 'react-router-dom'
import IndustrySum from './IndustrySum'
import CustomerSum from './CustomerSum'
import newBiz from './NewBiz'
import CsrBiz from './CsrBiz'
import IndustryBiz from './IndustryBiz'
// import './assets/style.scss'

const AnalysisIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/Industry'} component={IndustrySum} />
      <Route path={match.path + '/customer'} component={CustomerSum} />
      <Route path={match.path + '/newBiz'} component={newBiz} />
      <Route path={match.path + '/csrBiz'} component={CsrBiz} />
      <Route path={match.path + '/IndustryBiz'} component={IndustryBiz} />
      <Route component={IndustrySum} />
    </Switch>
  )
}

export default AnalysisIndex