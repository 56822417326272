/**
 * 模块名称: 代理商详情-客户信息
 * @author wangchanghong@372163.com
 */

import React, {useEffect, useState} from 'react'
import api from '@/api'
import {
    Form,
    Input,
    message, Select, Drawer
} from 'antd'
import BtnGroup from "../../../components/BtnGroup";

const FormItem = Form.Item

const Kehu = (props) => {

    const form = props.form;
    const {getFieldDecorator, validateFields, setFieldsValue} = form
    const {qId, kehu, refrashList, visible, setVisible} = props
    const [submitLoading, setSubmitLoading] = useState(false)
    const [hangye, setHangye] = useState([])
    const [industryFlag, setIndustryFlag] = useState(true)
    const [twoIndustryArr, setTwoIndustryArr] = useState([]) //二级行业

    useEffect(() => {
        setFieldsValue({
            name: '',
            website: '',
            industry1: undefined,
            industry2: undefined,
            contactMail: '',
            contactName: '',
            contactMobile: '',
            contactTel: ''
        })
    }, [kehu])

    useEffect(() => {
        api.getSysIndustryList({ pid: 0 }).then(res => {
            setHangye(res)
        })
    }, [])

    const onOk = () => {
        validateFields((err, vals) => {
            if (!err) {
                let submitVals = vals
                submitVals.qId = qId
                submitVals.id = kehu !== null ? kehu.id : 0

                setSubmitLoading(true)
                api.agentClientHandle(submitVals).then(
                    res => {
                        message.success("客户保存成功")
                        refrashList()
                        setVisible(false)
                    }
                ).finally(() => setSubmitLoading(false))

            } 
            // else {
            //     for (let i in err) {
            //         message.error(err[i].errors[0].message)
            //     }
            // }
        })
    }

    const onCancel = () => {
        setVisible(false)
    }

    const formItemLayout = {
        labelCol: {
            xs: {span: 24},
            sm: {span: 6},
        },
        wrapperCol: {
            xs: {span: 24},
            sm: {span: 18},
        }
    }

    // 获取二级行业
    const onSelectIndustry = (val) => {
        setFieldsValue({
            industry2: undefined
        })
        api.getSysIndustryList({
            pid: val,
        }).then(data => {
            setTwoIndustryArr(data)
            setIndustryFlag(false)
        })
    }

    return (
        <Drawer
            title="客户信息"
            visible={visible}
            width={600}
            onClose={() => setVisible(false)}
        >
            <Form  {...formItemLayout}>
                <FormItem label="客户名称">
                    {getFieldDecorator('name', {
                        rules: [{required: true, message: '请输入客户名称'}]
                    })(
                        <Input placeholder="请输入"/>
                    )}
                </FormItem>
                <FormItem label="网址">
                    {getFieldDecorator('website')(
                        <Input placeholder="请输入"/>
                    )}
                </FormItem>
                <FormItem label="一级行业">
                    {getFieldDecorator('industry1', {
                        rules: [{ required: true, message: '请选择一级行业' }]
                    })(
                        <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            showSearch={true}
                            optionFilterProp="children"
                            onSelect={onSelectIndustry}
                        >
                            {hangye.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    )}
                </FormItem>
                <FormItem label="二级行业">
                    {getFieldDecorator('industry2', {
                        rules: [{ required: twoIndustryArr.length === 0 ? false : true, message: '请选择二级行业' }]
                    })(
                        <Select placeholder="请选择"
                            style={{ width: 200 }}
                            dropdownMatchSelectWidth={false}
                            disabled={industryFlag}
                            showSearch={true}
                            optionFilterProp="children"
                        >
                            {twoIndustryArr && twoIndustryArr.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                        </Select>
                    )}
                </FormItem>
                <FormItem label="联系人">
                    {getFieldDecorator('contactName')(
                        <Input placeholder="请输入"/>
                    )}
                </FormItem>
                <FormItem label="手机">
                    {getFieldDecorator('contactMobile')(
                        <Input placeholder="请输入"/>
                    )}
                </FormItem>
                <FormItem label="座机">
                    {getFieldDecorator('contactTel')(
                        <Input placeholder="请输入，格式：xxx-xxxxxxx"/>
                    )}
                </FormItem>
                <FormItem label="邮箱">
                    {getFieldDecorator('contactMail')(
                        <Input placeholder="请输入"/>
                    )}
                </FormItem>
                <BtnGroup onCancel={onCancel} loading={submitLoading} onConfirm={onOk}/>
            </Form>
        </Drawer>
    )
}

export default Form.create()(Kehu)