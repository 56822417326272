/**
 * 模块名称: 客户新业务消耗汇总
 * @author zhuyan@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Icon,
  Input,
  Button,
  Select,
  Modal,
  message,
  Alert,
  DatePicker,
  Spin
} from 'antd'
import CorpMore from 'components/CorpMore'
import { parseSearch } from 'utils'
import Auth from '@/components/AuthMiddleware'
import { useSelector } from "react-redux"

let selectedIds = ''
const { Column } = Table
const FormItem = Form.Item
const Option = Select.Option
const { RangePicker, MonthPicker } = DatePicker

let pageSize = 10
let currentPage = 1
let corpId = ''
let searchParamsTmp = {
  limit: pageSize,
  page: currentPage,
  level: '1',
  dataType: '0',
  corpId: corpId
}
let storeFilterContent = {}
let allFilter = {}
let allData = [{
  id: '0',
  name: '全部'
}]
const dimensionArr = [
  ...allData,
  {
    id: 1,
    name: '直销消耗总和'
  }, {
    id: 2,
    name: '渠道消耗总和'
  }, {
    id: 3,
    name: '总消耗'
  }]
const levelArr = [
  {
    id: 1,
    name: '一级'
  }, {
    id: 2,
    name: '二级'
  }, {
    id: 3,
    name: '三级'
  }]

const CsrBiz = (props) => {
  const { history, match, location } = props
  const { getFieldDecorator, validateFields, resetFields, setFieldsValue, getFieldValue } = props.form
  const productsRef = useRef()
  const [filterName, setFilterName] = useState(undefined)
  const [count, setCount] = useState(0)
  const [totalNum, setTotalNum] = useState('')
  const [list, setList] = useState([])
  const [columnVal, setColumn] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterModelvisible, setFilterModelvisible] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [statusValName, setStatusValName] = useState(undefined)
  const [flag, setFlag] = useState(false)
  const [authList, setAuthList] = useState([])
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const search = parseSearch(location.search)
  const [selectData, setSelectData] = useState({
    industry1Arr: [], // 一级
    industry2Arr: [], // 二级
    isRequest: false
  })
  const [numFlag, setNumFlag] = useState(true)
  const [listFlag, setListFlag] = useState(true)
  const userInfo = useSelector(state => state.userInfo)
  const [downloadDis, setonDownloadDis] = useState(false)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      level: '1',
      dataType: '0',
    }
    corpId = ''
    storeFilterContent = {}
    getSelectData()
    getnewBusinessConsumptionList()
    getnewBusinessClientTotal()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    }
  }

  //点击选择所属公司
  const onChangeCorp = (data) => {
    currentPage = 1
    history.replace(match.path)
    if (data.length) {
      corpId = data && data.map(item => item.id).join(',')
      searchParamsTmp.corpId = corpId
    } else {
      corpId = ''
      searchParamsTmp.corpId = ''
    }
    // getnewBusinessConsumptionList()
  }

  //点击重置
  const onReset = () => {
    currentPage = 1
    history.replace(match.path)
    searchParamsTmp = {
      limit: pageSize,
      page: currentPage,
      level: '1',
      dataType: '0',
      corpId: corpId
    }
    resetFields()
    setFilterName(undefined)
    setStatusValName(undefined)
    getnewBusinessConsumptionList()
    getnewBusinessClientTotal()
    onIndustryTwo()
  }

  //分页点击
  const onChangeTable = (pagination, filters, sorter) => {
    searchParamsTmp.page = currentPage = pagination.current
    searchParamsTmp.limit = pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getnewBusinessConsumptionList()
  }

  // 获取列表
  const getnewBusinessConsumptionList = () => {
    setList([])
    setColumn([])
    setLoading(true)
    searchParamsTmp.corpId = corpId
    setListFlag(false)
    api.getnewBusinessConsumptionList(searchParamsTmp).then(res => {
      setList(res.list)
      res.titleList && res.titleList.forEach((item, index, arr) => {
        if (item.dataIndex == 'oaid') {
          arr[index] = { ...item, fixed: 'left', width: '100px' }
        }
      })
      setColumn(res.titleList)
      setListFlag(true)
      setLoading(false)
    }).catch(() => {
      setListFlag(true)
      setLoading(false)
    })
  }

  // 获取统计
  const getnewBusinessClientTotal = () => {
    setCount(0)
    setTotalNum('')
    setNumFlag(false)
    searchParamsTmp.corpId = corpId
    api.getnewBusinessClientTotal(searchParamsTmp).then(res => {
      setCount(res.count)
      setTotalNum(res)
      setNumFlag(true)
    }).catch(() => {
      setNumFlag(true)
    })
  }

  //获取筛选项
  const getSelectData = () => {
    return Promise.all([
      api.getSysIndustryList({ pid: 0 }),
      api.getSysIndustryList({ pid: -1 }),
    ]).then(list => {
      setSelectData({
        industry1Arr: [...allData, ...list[0]],
        industry2Arr: [...allData, ...list[1]],
        isRequest: true
      })
      return list
    }).catch()
  }

  // 选择一级行业
  const onSelectIndustry = (val) => {
    setFieldsValue({
      industry2: '0'
    })
    api.getSysIndustryList({
      pid: val !== '0' ? val : -1,
    }).then(data => {
      setSelectData({
        ...selectData,
        industry2Arr: [...allData, ...data],
      })
    })
  }

  // 获取二级行业
  const onIndustryTwo = () => {
    api.getSysIndustryList({
      pid: getFieldValue('industry1') !== '0' ? getFieldValue('industry1') : -1,
    }).then(data => {
      setSelectData({
        ...selectData,
        industry2Arr: [...allData, ...data],
      })
    })
  }

  //数据转换
  const transData = (vals) => {
    if (vals !== undefined) {
      for (let i in vals) {
        if (vals[i] !== undefined) {
          if (i == 'addTime') {
            searchParamsTmp.orderDateMin = moment(vals[i][0]).format('YYYY-MM-DD')
            searchParamsTmp.orderDateMax = moment(vals[i][1]).format('YYYY-MM-DD')
          } else if (i == 'month') {
            searchParamsTmp.month = moment(vals[i]).format('YYYY年MM月')
          } else if (i == 'industry1') {
            if (vals[i] == '0') {
              searchParamsTmp.industry1 = ''
            } else {
              searchParamsTmp.industry1 = vals[i]
            }
          } else if (i == 'industry2') {
            if (vals[i] == '0') {
              searchParamsTmp.industry2 = ''
            } else {
              searchParamsTmp.industry2 = vals[i]
            }
          } else {
            searchParamsTmp[i] = vals[i]
          }
        }
      }
      // console.log('searchParamsTmp=>', searchParamsTmp)
    }
  }

  //点击筛选
  const searchSubmit = (e) => {
    e.preventDefault()
    setonDownloadDis(false)
    validateFields((err, vals) => {
      transData(vals)
      if (!err) {
        searchParamsTmp.page = currentPage = 1
        history.replace(match.path)
        getnewBusinessConsumptionList()
        getnewBusinessClientTotal()
      }
    })
  }

  //选择保存方案
  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    // 下拉框搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(res => {
      setFilterContent(res.list)
    })
  }

  //选择方案
  const onFilterChecked = (item, val2) => {
    // console.log('item=>', item)
    setFilterName(item)
    setStatusValName(val2.props.children.props.children[0])
    let data = filterContent[item].content
    searchParamsTmp = { ...searchParamsTmp, ...data }
    setFieldsValue({
      industry1: data.industry1 ? data.industry1 : undefined,
      industry2: data.industry2 ? data.industry2 : undefined,
      level: data.level ? data.level : undefined,
      dataType: data.dataType ? data.dataType : undefined,
      addTime: data.addTime ? [moment(data.addTime[0]), moment(data.addTime[1])] : undefined
    })
  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  //保存筛选条件
  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      //console.log(vals)
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item === '' || item !== undefined)) {
          storeFilterContent = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'addTime' && vals[k] !== '') {
                storeFilterContent[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else {
                storeFilterContent[k] = vals[k]
              }
            }
          }
          setFilterModelvisible(true)
        } else {
          message.error('请选择筛选内容')
        }
      }
    })
  }

  //保存筛选点击确定
  const filterHandleOk = () => {
    if (filterInfoValue.trim() === '') {
      message.error('筛选名称不能为空')
      return
    }
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: storeFilterContent
    }).then(() => {
      setFilterModelvisible(false)
      message.success('添加成功')
    })

  }

  //取消筛选点击确定
  const filterHandleCancel = () => {
    setFilterModelvisible(false)
  }

  //修改筛选名称
  const onFilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value)
  }

  //导出
  const onExportFile = () => {
    setonDownloadDis(true)
    api.getHost().then(res => {
      let args = JSON.parse(JSON.stringify(searchParamsTmp))
      delete args.limit
      delete args.page
      let searchParams = new URLSearchParams()
      for (let key in args) {
        if (args[key] && args[key] !== '') {
          searchParams.set(key, args[key]);
        }
      }
      searchParams.set('menuId', 214)
      searchParams.set('userId', userInfo.id)
      window.location = `${res.url}/api/fortune/newBusinessConsumption/clientExport?${searchParams.toString()}`
    })
    setTimeout(() => {
      setonDownloadDis(false)
    }, 5000)
  }

  return (
    <>
      <CorpMore onChange={onChangeCorp} />
      <div className="search-askforleave-wrap">
        <h4 className="title">其它选项：</h4>
        {
          !showAll ? <span className="askforleave-filter-control" onClick={() => setShowAll(true)}>展开<Icon type="down" /></span>
            : <span className="askforleave-filter-control" onClick={() => setShowAll(false)}>收起<Icon type="up" /></span>
        }
        <Form onSubmit={searchSubmit} {...formItemLayout}>
          <div className={`form-box${showAll ? ' askforleave-form-auto' : ''}`}>
            <FormItem label="一级行业">
              {getFieldDecorator('industry1', { initialValue: '0' })(<Select placeholder="全部"
                dropdownMatchSelectWidth={false}
                onSelect={(val) => onSelectIndustry(val)}
                showSearch={true}
                optionFilterProp="children"
              >
                {selectData.industry1Arr && selectData.industry1Arr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
              </Select>
              )}
            </FormItem>
            <FormItem label="二级行业">
              {getFieldDecorator('industry2', { initialValue: '0' })
                (<Select placeholder="全部"
                  dropdownMatchSelectWidth={false}
                  onChange={(val) => onIndustryTwo(val)}
                  showSearch={true}
                  optionFilterProp="children"
                >
                  {selectData.industry2Arr && selectData.industry2Arr.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
                )}
            </FormItem>
            <FormItem label="订单生效时间" style={{ width: '40%' }}>
              {getFieldDecorator('addTime')(<RangePicker allowClear={false} />)}
            </FormItem>
            <FormItem label="新业务">
              {getFieldDecorator('level', { initialValue: '1' })(<Select
                placeholder="全部"
                dropdownMatchSelectWidth={false}
                showSearch
                optionFilterProp="children"
              >
                {
                  levelArr && levelArr.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
            <FormItem label="数据维度">
              {getFieldDecorator('dataType', { initialValue: '0' })(<Select
                placeholder="全部"
                showSearch
                optionFilterProp="children"
              >
                {
                  dimensionArr && dimensionArr.map((item, index) => {
                    return <Option key={item.id}>{item.name}</Option>
                  })
                }
              </Select>)}
            </FormItem>
          </div>
          <FormItem className="btns" label=" " colon={false}>
            <div className="staff-filter-btns">
              {showAll && <>
                <h4 className="filter-title">已保存筛选方案：</h4>
                <div className="filter-select">
                  <Select style={{ display: 'inline-block', width: '160px' }} placeholder="请选择" onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)} onSelect={onFilterChecked} value={filterName}>
                    {filterContent.map((item, index) =>
                      <Option value={index} key={index}>
                        <div className="closeStyBtn">
                          {item.name}
                          {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                            e.stopPropagation()
                            onSearchDelete(item.id)
                          }} /></span> : null}
                        </div>
                      </Option>
                    )}
                  </Select>
                </div>
                <Button className="filter-savebtn" onClick={onSaveFilterTerm}>保存筛选条件</Button>
              </>}
              <Auth auths={authList} code="export">
                <Button type="primary" icon="upload" disabled={downloadDis} onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
              </Auth>
              <Button onClick={onReset}>重置</Button>
              <Button type="primary" htmlType="submit" disabled={!listFlag || !numFlag}>筛选</Button>
              <Modal
                title="保存筛选条件"
                visible={filterModelvisible}
                onOk={filterHandleOk}
                onCancel={filterHandleCancel}
              >
                <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={onFilterInfoChange} />
              </Modal>
            </div>
          </FormItem>
        </Form>
      </div>
      <div className="line"></div>
      <Spin spinning={!numFlag}>
        <Alert style={{ marginTop: 18 }} message={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div></div>
            <div>
              <span>{`数据总计: ${count}条数据`}</span> &nbsp;&nbsp;&nbsp;
                  <span>{`直销总消耗: ${totalNum && totalNum.zxTotalConsumption}`}</span> &nbsp;&nbsp;&nbsp;
                  <span>{`渠道总消耗: ${totalNum && totalNum.qdTotalConsumption}`}</span> &nbsp;&nbsp;&nbsp;
                  <span>{`合计总消耗: ${totalNum && totalNum.totalConsumption}`}</span> &nbsp;&nbsp;
              </div>
          </div>
        } type="info" />
      </Spin>
      <Table
        size="small"
        columns={columnVal}
        dataSource={list}
        rowKey={(record, i) => i}
        loading={loading}
        bordered
        // size="middle"
        className="askforleave-table"
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
        scroll={{ x: 'max-content' }}
      // scroll={{ x: 2400 }}
      >
      </Table>
    </>
  )
}

export default Form.create()(CsrBiz)